import aucklandStadiumsLogoSquarePng from 'static/images/logos/auckland-stadiums-logo-square.png';
import { phoneNumber } from 'config/contact';
import React from 'react';
import stadiumsJsonLd from 'config/stadiums-structured-data';

const corporateContactStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.aucklandstadiums.co.nz/',
    logo: `https://www.aucklandstadiums.co.nz${aucklandStadiumsLogoSquarePng}`,
    contactPoint: [
        {
            '@type': 'ContactPoint',
            telephone: phoneNumber,
            contactType: 'customer service'
        }
    ],
    sameAs: [
        'https://www.instagram.com/auckland_stadiums/'
    ]
};

const localBusinessStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    '@id': 'https://www.aucklandstadiums.co.nz/',
    name: 'Auckland Stadiums',
    image: `https://www.aucklandstadiums.co.nz${aucklandStadiumsLogoSquarePng}`,
    address: {
        '@type': 'PostalAddress',
        addressCountry: 'New Zealand',
        addressLocality: 'Auckland',
        addressRegion: 'Auckland',
        postalCode: '1010',
        streetAddress: '100 Mayoral Drive'
    },
    geo: {
        '@type': 'GeoCoordinates',
        latitude: -36.8580303,
        longitude: 174.7583001
    },
    telephone: phoneNumber,
    url: 'https://www.aucklandstadiums.co.nz/',
    openingHoursSpecification: [
        {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday'
            ],
            opens: '8:30',
            closes: '17:00'
        },
        {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: [
                'Saturday',
                'Sunday'
            ],
            opens: '00:00',
            closes: '00:00'
        }
    ],
    department: stadiumsJsonLd
};

export default () => ( // eslint-disable-line react/display-name
    <div>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(corporateContactStructuredData) }}
        />
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(localBusinessStructuredData) }}
        />
    </div>
);
