import mtSmartThumbnail from 'static/images/featured-stadiums-thumbnails/mt-smart.jpg';
import northHarbourThumbnail from 'static/images/featured-stadiums-thumbnails/north-harbour.jpg';
import westernSpringsThumbnail from 'static/images/featured-stadiums-thumbnails/western-springs.jpg';

const stadiumsJsonLd = [
    {
        '@type': 'StadiumOrArena',
        '@id': 'https://www.aucklandstadiums.co.nz/our-venues/go-media-stadium',
        name: 'Go Media Stadium',
        image: `https://www.aucklandstadiums.co.nz${mtSmartThumbnail}`,
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'New Zealand',
            addressLocality: 'Auckland',
            addressRegion: 'Auckland',
            postalCode: '1061',
            streetAddress: '2 Beasley Ave'
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: -36.9182878,
            longitude: 174.81018
        },
        telephone: '+6493662048',
        url: 'https://www.aucklandstadiums.co.nz/our-venues/go-media-stadium',
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday'
                ],
                opens: '8:30',
                closes: '17:00'
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Saturday',
                    'Sunday'
                ],
                opens: '00:00',
                closes: '00:00'
            }
        ]
    },
    {
        '@type': 'StadiumOrArena',
        '@id': 'https://www.aucklandstadiums.co.nz/our-venues/western-springs-stadium',
        name: 'Western Springs Stadium',
        image: `https://www.aucklandstadiums.co.nz${westernSpringsThumbnail}`,
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'New Zealand',
            addressLocality: 'Auckland',
            addressRegion: 'Auckland',
            postalCode: '1022',
            streetAddress: 'Great North Rd'
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: -36.8651768,
            longitude: 174.7240035
        },
        telephone: '+6493662048',
        url: 'https://www.aucklandstadiums.co.nz/our-venues/western-springs-stadium',
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday'
                ],
                opens: '8:30',
                closes: '17:00'
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Saturday',
                    'Sunday'
                ],
                opens: '00:00',
                closes: '00:00'
            }
        ]
    },
    {
        '@type': 'StadiumOrArena',
        '@id': 'https://www.aucklandstadiums.co.nz/our-venues/north-harbour-stadium',
        name: 'North Harbour Stadium',
        image: `https://www.aucklandstadiums.co.nz${northHarbourThumbnail}`,
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'New Zealand',
            addressLocality: 'Auckland',
            addressRegion: 'Auckland',
            postalCode: '0632',
            streetAddress: 'Stadium Dr'
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: -36.7268156,
            longitude: 174.6993844
        },
        telephone: '+6493747525',
        url: 'https://www.aucklandstadiums.co.nz/our-venues/north-harbour-stadium',
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday'
                ],
                opens: '8:30',
                closes: '17:00'
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Saturday',
                    'Sunday'
                ],
                opens: '00:00',
                closes: '00:00'
            }
        ]
    }
];

export const getStadiumJsonLdByName = (stadiumName) => {
    return stadiumsJsonLd.find(({ name }) => name === stadiumName);
};

export default stadiumsJsonLd;
