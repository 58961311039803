import ACLogo from 'static/images/ac-whanau-tile-right.svg';
import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import { toggleStripAction } from 'app/ducks/strip';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Strip = ({ isActive }) => {
    const isDisplay = useSelector((state) => state.strip.isDisplay);
    const dispatch = useDispatch();
    const stripClass = classnames('strip', { 'is-active': isDisplay && isActive });

    const toggleStrip = useCallback(() => {
        dispatch(toggleStripAction());
    }, []);

    return (
        <div className={stripClass}>
            <section className="inner constrain-width">
                <img className="logo" src={ACLogo} alt="Part of the Auckland Council Whānau" />
                <button className="icon-button" onClick={toggleStrip}>
                    <Icon
                        className="icon-close"
                        name="cross"
                    />
                </button>
            </section>
        </div>
    );
};

Strip.propTypes = {
    isActive: PropTypes.bool.isRequired
};

export default Strip;
