import PropTypes from 'prop-types';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { withScriptjs } from 'react-google-maps';
import React, { Component } from 'react';

class PlacesSearchBox extends Component {
    constructor(props) {
        super(props);

        this.searchBox = null;
    }

    render() {
        const aucklandCoordinates = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(-36.923631, 174.801167), // eslint-disable-line
            new window.google.maps.LatLng(-36.912943, 174.819192) // eslint-disable-line
        );

        return (
            <div data-standalone-searchbox="" className={this.props.wrapperClassName}>
                <StandaloneSearchBox
                    ref={(searchBox) => { this.searchBox = searchBox; } }
                    bounds={aucklandCoordinates}
                    onPlacesChanged={() => {
                        const places = this.searchBox.getPlaces();

                        if (places.length) {
                            const location = places[0].geometry.location;

                            return this.props.onPlacesChanged({ lat: location.lat(), lng: location.lng() });
                        }

                        this.props.onPlacesChanged(null);
                    }}
                >
                    <input
                        type="text"
                        placeholder={this.props.placeholder}
                        className={this.props.inputClassName}
                        name={this.props.name}
                        id={this.props.name}
                    />
                </StandaloneSearchBox>
            </div>
        );
    }
}

PlacesSearchBox.propTypes = {
    inputClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    onPlacesChanged: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    wrapperClassName: PropTypes.string
};

export default withScriptjs(PlacesSearchBox);
