import { getStadiumJsonLdByName } from 'config/stadiums-structured-data';
import React from 'react';

function createPerformancesJsonLd(data) {
    const {
        hero_image: heroImage,
        name,
        performances,
        seo_description: seoDescription,
    } = data;

    return (performances || []).map((performance) => {
        const {
            starts_at: startsAt,
            venue_name: venueName
        } = performance;

        return {
            '@context': 'https://schema.org',
            '@type': 'Event',
            name,
            startDate: startsAt,
            location: getStadiumJsonLdByName(venueName),
            image: heroImage && heroImage.images && heroImage.images.desktop || [],
            description: seoDescription
        };
    });
}

export default (data) => ( // eslint-disable-line react/display-name
    <div>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(createPerformancesJsonLd(data)) }}
        />
    </div>
);
