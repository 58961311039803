let loadYouTubeIframeAPI = null;

export const loadYouTubeAPI = () => {
    if (!loadYouTubeIframeAPI) {
        loadYouTubeIframeAPI = new Promise((resolve) => {
            if (window.YT && window.YT.Player) {
                resolve(window.YT);
            } else {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                window.onYouTubeIframeAPIReady = () => resolve(window.YT);
            }
        });
    }

    return loadYouTubeIframeAPI;
};
