import classnames from 'classnames';
import defaultBannerImage from 'static/images/default-page-banner.jpg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const SLIDE_ANIMATION_DURATION = 600;
const SLIDE_ROTATION_DURATION = 5000;

export const VIEW = { mobile: 'mobile', desktop: 'desktop' };

/**
 * Hero Carousel component is designed to be used as the page header on both homepage and normal CMS pages.
 * @param {String} introduction - The page description
 * @param {Array} images - The images array
 * @param {String} title - The page title
 * @param {String} type - The type of hero carousel. Default is empty, "primary" should be used for homepage
 * @param {String} view - The view type can be mobile or desktop. Default is empty.
 * to fit the stadiums list
 * @returns {Component}
 */
class HeroCarousel extends Component {
    constructor() {
        super();

        this.state = HeroCarousel.createInitialState();

        this.autoRotate = null;
    }

    static createInitialState() {
        return {
            activeSlide: 0,
            autoRotate: true,
            nextReady: true
        };
    }

    // Lifecycle
    componentDidMount() {
        if (this.props.images.length > 1) {
            this.startAutoRotate();
        }
    }

    UNSAFE_componentWillUnmount() {
        this.stopAutoRotate();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.images === nextProps.images) {
            this.stopAutoRotate();
            this.setState(HeroCarousel.createInitialState());
        }
    }


    // Helper
    isNext(index) {
        const { activeSlide, nextReady } = this.state;

        // If last image, first one becomes 'next' as well
        if (nextReady && index === 0 && activeSlide !== index) {
            return true;
        }

        return nextReady && ((activeSlide + 1 === index) || (activeSlide - 1 === index));
    }

    nextReadyAfterTime() {
        setTimeout(() => {
            this.setState({
                nextReady: true
            });
        }, SLIDE_ANIMATION_DURATION);
    }

    startAutoRotate() {
        this.autoRotate = setInterval(() => {
            const shouldRotate = this.state.autoRotate;
            const isLastSlide = (this.state.activeSlide + 1 === this.props.images.length);

            if (shouldRotate && isLastSlide) {
                // Restart carousel
                this.updateSlide(0);
            } else if (shouldRotate) {
                this.nextSlide();
            } else {
                // Stop the rotation
                this.stopAutoRotate();
            }
        }, SLIDE_ROTATION_DURATION);
    }

    stopAutoRotate() {
        clearInterval(this.autoRotate);
    }

    previousSlide() {
        const nextActiveSlide = Math.max(this.state.activeSlide - 1, 0);

        this.updateSlide(nextActiveSlide);
    }

    nextSlide() {
        const nextActiveSlide = Math.min(this.state.activeSlide + 1, this.props.images.length - 1);

        this.updateSlide(nextActiveSlide);
    }

    updateSlide(slide = 0) {
        this.setState({
            activeSlide: slide,
            nextReady: false
        }, this.nextReadyAfterTime);
    }


    // Handler
    handlePrevious() {
        this.setState({
            autoRotate: false
        }, this.previousSlide());
    }

    handleNext() {
        this.setState({
            autoRotate: false
        }, this.nextSlide());
    }


    // Render
    render() {
        const { compact, introduction, images, light, title, type, view } = this.props;
        const { activeSlide } = this.state;
        const heroCarouselClass = classnames('hero-carousel', {
            'primary': type === 'primary',
            'mobile': (view === VIEW.mobile),
            'desktop': (view === VIEW.desktop),
            'compact': compact,
            'light': light,
            'no-negative-margin' : true
        });

        if (images.length === 0) images.push(defaultBannerImage);

        return (
            <div className={heroCarouselClass}>
                <div className="hero-carousel-intro">
                    <h1 className="hero-carousel-heading || constrain-width">{title}</h1>
                    {introduction && <div className="hero-carousel-description || constrain-width">{introduction}</div>}
                </div>

                {!light && (
                    <div className="carousel">
                        <div className="carousel-inner || preserve-aspect-ratio">
                            {images.map((image, index) => {
                                const itemClass = classnames('carousel-media', {
                                    'is-active': (activeSlide === index),
                                    'is-next': this.isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={`carousel-media-${index}`}>
                                        <div className="carousel-image || preserve-aspect-ratio-media || image-overlay" style={{ backgroundImage: `url(${image})` }}>
                                            <img className="fixed-image" src={image} alt={title} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

HeroCarousel.propTypes = {
    compact: PropTypes.bool.isRequired,
    introduction: PropTypes.any,
    images: PropTypes.array.isRequired,
    light: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    view: PropTypes.string
};

HeroCarousel.defaultProps = {
    compact: false,
    light: false,
    showArrows: false
};

export default HeroCarousel;
