import classnames from 'classnames';
import Icon from './icon';
import { NAVIGATION_HEADER } from 'config/navigation.js';
import PropTypes from 'prop-types';
import React from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom'; // eslint-disable-line

const Navigation = (props) => {
    const location = useLocation();

    // Handlers
    const handleToggleChildNavigation = (index) => {
        const { itemOpen, updateOpenItem } = props;

        updateOpenItem(itemOpen === index ? null : index);
    };


    // Render
    const renderNavigationItem = ({ children, label, link, isNonClickable }, index) => {
        const itemClass = classnames('navigation-item', {
            'is-active': props.itemOpen === index
        });

        const isActive = matchPath({
            path: link,
            exact: false,
            strict: false
        }, location.pathname);

        return (
            <div className={itemClass} key={index}>
                {isNonClickable ? (
                    <span className={classnames('navigation-link is-non-clickable', { 'is-active': isActive })}>{label}</span>
                ) : (
                    <NavLink
                        className={({ isActive }) => classnames('navigation-link', { 'is-active': isActive })}
                        to={link}
                    >
                        {label}
                    </NavLink>
                )}
                {children &&
                    <button type="button" className="sub-navigation-toggle" onClick={() => handleToggleChildNavigation(index)}>
                        <Icon name="arrow-down" title="Open subpages" />
                    </button>}
                {children &&
                    <nav className="sub-navigation">
                        {children.map(({ label, link }, subIndex) => {
                            // Render Link external links
                            if (link.indexOf('http') > -1) {
                                return (
                                    <a className="sub-navigation-item" href={link} key={`${index}-${subIndex}`}>{label}</a>
                                );
                            }

                            return (
                                <NavLink
                                    className={({ isActive }) => classnames('sub-navigation-item', {
                                        'is-active': isActive
                                    })}
                                    to={link}
                                    key={`${index}-${subIndex}`}
                                    end
                                >
                                    {label}
                                </NavLink>
                            );
                        })}
                    </nav>}
            </div>
        );
    };

    const { isActive } = props;

    const navigationClass = classnames('navigation-bar', {
        'is-active': isActive
    });

    return (
        <div className={navigationClass}>
            <div className="header-actions">
                <button
                    type="button"
                    className="action-button"
                    onClick={props.toggleActive}>
                    <span className="action-button-inner">
                        {isActive
                            && <Icon name="cross" />
                            || <Icon name="menu" />
                        }
                    </span>
                </button>
            </div>
            <div className="navigation-bar-inner">
                <nav className="navigation">
                    {NAVIGATION_HEADER.map((item, index) => {
                        return renderNavigationItem(item, index);
                    })}
                </nav>
            </div>
        </div>
    );
};

Navigation.propTypes = {
    isActive: PropTypes.bool.isRequired,
    itemOpen: PropTypes.number, // Not required so we can pass null
    toggleActive: PropTypes.func.isRequired,
    updateOpenItem: PropTypes.func.isRequired
};

export default Navigation;
