import mtSmartThumbnailImageUrl from 'static/images/featured-stadiums-thumbnails/mt-smart.jpg';
import northHarbourThumbnailImageUrl from 'static/images/featured-stadiums-thumbnails/north-harbour.jpg';
import westernSpringsThumbnailImageUrl from 'static/images/featured-stadiums-thumbnails/western-springs.jpg';

export default [
    {
        label: 'Go Media Stadium',
        pageLink: '/our-venues/go-media-stadium',
        directionsLink: 'https://www.google.com/maps/search/?api=1&query=Mt+Smart+Stadium&query_place_id=ChIJVeEWutdIDW0RGEROrR2Oeco',
        thumbnailImageUrl: mtSmartThumbnailImageUrl,
        detail: 'New Zealand\'s premier outdoor concert and festival venue, Go Media Stadium hosts some of the world’s best entertainment acts.',
        socials: [
            {
                label: 'instagram',
                link: 'https://www.instagram.com/auckland_stadiums/',
                icon: 'instagram'
            },
            {
                label: 'facebook',
                link: 'https://www.facebook.com/gomediastadiummtsmart/',
                icon: 'facebook'
            }
        ]
    },
    {
        label: 'Western Springs Stadium',
        pageLink: '/our-venues/western-springs-stadium',
        directionsLink: 'https://www.google.com/maps/search/?api=1&query=Western+Springs+Stadium+and+Park&query_place_id=ChIJZUa9rg5HDW0Ryad_qWEF3Zw',
        thumbnailImageUrl: westernSpringsThumbnailImageUrl,
        detail: 'Just 3 kilometres from downtown Auckland, the natural amphitheatre Western Springs hosts crowds up to 55,000 for concerts and festivals.',
        socials: [
            {
                label: 'instagram',
                link: 'https://www.instagram.com/auckland_stadiums/',
                icon: 'instagram'
            },
            {
                label: 'facebook',
                link: 'https://www.facebook.com/westernspringsstadium',
                icon:'facebook'
            }
        ]
    },
    {
        label: 'North Harbour Stadium',
        pageLink: '/our-venues/north-harbour-stadium',
        directionsLink: 'https://www.google.com/maps/search/?api=1&query=North+Harbour+Stadium&query_place_id=ChIJsaB0vZM7DW0RW2eN7EWwYvs',
        thumbnailImageUrl: northHarbourThumbnailImageUrl,
        detail: 'Located in Albany on Auckland\'s North Shore, North Harbour Stadium (formerly QBE Stadium) is purpose built for New Zealand\'s favourite sporting codes of rugby and football.',
        socials: [
            {
                label: 'instagram',
                link: 'https://www.instagram.com/auckland_stadiums/',
                icon: 'instagram'
            },
            {
                label: 'facebook',
                link: 'https://www.facebook.com/NorthHarbourStadium',
                icon:'facebook'
            }
        ]
    }
];
