/* eslint-disable quotes */
export const NAVIGATION_FOOTER = [
    {
        label: `What's on`,
        link: '/whats-on'
    }
];

export const NAVIGATION_HEADER = [
    {
        label: `What's on`,
        link: '/whats-on'
    },
    {
        label: 'Visitor info',
        link: '/visitor-info',
        isNonClickable: true,
        children: [
            {
                label: 'Tickets',
                link: '/visitor-info/tickets'
            },
            {
                label: 'Conditions of entry',
                link: '/visitor-info/conditions-of-entry'
            },
            {
                label: 'Accessibility',
                link: '/visitor-info/accessibility'
            },
            {
                label: 'Go Karts',
                link: '/visitor-info/go-karts'
            }
        ]
    },
    {
        label: 'Our venues',
        link: '/our-venues',
        children: [
            {
                label: 'Go Media Stadium',
                link: '/our-venues/go-media-stadium'
            },
            {
                label: 'Western Springs Stadium',
                link: '/our-venues/western-springs-stadium'
            },
            {
                label: 'North Harbour Stadium',
                link: '/our-venues/north-harbour-stadium'
            },
            {
                label: 'Lilyworld',
                link: 'https://lilyworld.co.nz/'
            }
        ]
    },
    {
        label: 'Functions',
        link: '/functions'
    },
    {
        label: 'About us',
        link: '/about-us',
        children: [
            {
                label: 'Who we are',
                link: '/about-us/who-we-are'
            },
            {
                label: 'Get involved',
                link: '/about-us/get-involved'
            },
            {
                label: `News`,
                link: '/news'
            },
            {
                label: 'Work for us',
                link: '/about-us/work-for-us'
            },
            {
                label: 'Contact us',
                link: '/about-us/contact-us'
            }
        ]
    }
];
