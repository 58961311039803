import { MT_SMART_STADIUM_CAR_PARKS } from 'config/venue-car-parks';
import { MT_SMART_STADIUM_GATES } from 'config/venue-gates';

// Bay coordinates based on the stadiums map position on Google Maps
export const MT_SMART_STADIUM = [
    {
        bayNumber: '1',
        lat: -36.917702,
        lng: 174.811493,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '2',
        lat: -36.917759,
        lng: 174.811466,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '3',
        lat: -36.917874,
        lng: 174.811450,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '4',
        lat: -36.918003,
        lng: 174.811447,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '5',
        lat: -36.918136,
        lng: 174.811455,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '6',
        lat: -36.918259,
        lng: 174.811448,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '7',
        lat: -36.918383,
        lng: 174.811420,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '8',
        lat: -36.918509,
        lng: 174.811405,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '9',
        lat: -36.918628,
        lng: 174.811417,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '10',
        lat: -36.917815,
        lng: 174.811755,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '11',
        lat: -36.917929,
        lng: 174.811746,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '12',
        lat: -36.918041,
        lng: 174.811746,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '13',
        lat: -36.918144,
        lng: 174.811736,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '14',
        lat: -36.918245,
        lng: 174.811727,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '15',
        lat: -36.918331,
        lng: 174.811722,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '16',
        lat: -36.918426,
        lng: 174.811717,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '17',
        lat: -36.918530,
        lng: 174.811707,
        gate: MT_SMART_STADIUM_GATES.GATE_A,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '19',
        lat: -36.918735,
        lng: 174.811899,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '20',
        lat: -36.918738,
        lng: 174.812015,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '21',
        lat: -36.918746,
        lng: 174.812172,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '22',
        lat: -36.918758,
        lng: 174.812307,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '23',
        lat: -36.918763,
        lng: 174.812452,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '24',
        lat: -36.918761,
        lng: 174.812600,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '25',
        lat: -36.918769,
        lng: 174.812711,
        gate: MT_SMART_STADIUM_GATES.GATE_D,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '18U',
        lat: -36.918865,
        lng: 174.811818,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '19U',
        lat: -36.918874,
        lng: 174.811940,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '20U',
        lat: -36.918879,
        lng: 174.812073,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '21U',
        lat: -36.918882,
        lng: 174.812217,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '22U',
        lat: -36.918892,
        lng: 174.812378,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '23U',
        lat: -36.918898,
        lng: 174.812517,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '24U',
        lat: -36.918900,
        lng: 174.812655,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '25U',
        lat: -36.918909,
        lng: 174.812783,
        gate: MT_SMART_STADIUM_GATES.GATE_E,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_1
    },
    {
        bayNumber: '26',
        lat: -36.918610,
        lng: 174.812926,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '27',
        lat: -36.918519,
        lng: 174.812932,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '28',
        lat: -36.918405,
        lng: 174.812935,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '29',
        lat: -36.918317,
        lng: 174.812942,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '30',
        lat: -36.918238,
        lng: 174.812952,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '31',
        lat: -36.918155,
        lng: 174.812959,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '32',
        lat: -36.918059,
        lng: 174.812958,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '33',
        lat: -36.917951,
        lng: 174.812967,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '34',
        lat: -36.917861,
        lng: 174.812973,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '35',
        lat: -36.918599,
        lng: 174.813254,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '36',
        lat: -36.918509,
        lng: 174.813260,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '37',
        lat: -36.918418,
        lng: 174.813269,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '38',
        lat: -36.918312,
        lng: 174.813274,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '39',
        lat: -36.918239,
        lng: 174.813279,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '40',
        lat: -36.918153,
        lng: 174.813284,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '41',
        lat: -36.918061,
        lng: 174.813291,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '42',
        lat: -36.917963,
        lng: 174.813299,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '43',
        lat: -36.917881,
        lng: 174.813302,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '51',
        lat: -36.918605,
        lng: 174.813029,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '52',
        lat: -36.918500,
        lng: 174.813032,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '53',
        lat: -36.918404,
        lng: 174.813040,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '54',
        lat: -36.918301,
        lng: 174.813032,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '55',
        lat: -36.918232,
        lng: 174.813055,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '56',
        lat: -36.918150,
        lng: 174.813061,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '57',
        lat: -36.918051,
        lng: 174.813065,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '58',
        lat: -36.917944,
        lng: 174.813073,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    {
        bayNumber: '59',
        lat: -36.917875,
        lng: 174.813077,
        gate: MT_SMART_STADIUM_GATES.GATE_C,
        parking: MT_SMART_STADIUM_CAR_PARKS.PARKING_3
    },
    // Custom field bays for Taylor Swift
    {
        bayNumber: 'A1',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A2',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A3',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A4',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A5',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A6',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A7',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A8',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A9',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A10',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A11',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A12',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A13',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'A14',
        lat: -36.917934,
        lng: 174.812347,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'B1',
        lat: -36.918123,
        lng: 174.812355,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'B2',
        lat: -36.918123,
        lng: 174.812355,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'B3',
        lat: -36.918123,
        lng: 174.812355,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'B4',
        lat: -36.918123,
        lng: 174.812355,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'B5',
        lat: -36.918123,
        lng: 174.812355,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C1',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C2',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C3',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C4',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C5',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C6',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'C7',
        lat: -36.918300,
        lng: 174.812335,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D1',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D2',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D3',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D4',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D5',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D6',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    },
    {
        bayNumber: 'D7',
        lat: -36.918488,
        lng: 174.812342,
        gate: MT_SMART_STADIUM_GATES.GATE_F
    }
];
