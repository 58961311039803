import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import Map from 'app/components/partials/map';
import mtSmartStadiumOverlay from 'static/images/mt-smart-stadium.svg';
import PlacesSearchBox from 'app/components/partials/places-search-box';
import PropTypes from 'prop-types';
import React from 'react';
import SeatFinder from 'app/components/partials/seat-finder';
import seatLocationImage from 'static/images/seat-location.png';
import { DIRECTIONS_OPTIONS, FIND_SEAT_OPTIONS } from '../../../config/map';
import { GOOGLE_MAPS_API_KEY, STADIUMS_LOCATION } from 'config/map';
import { GroundOverlay, Marker } from 'react-google-maps';

const SECTIONS = {
    directions: 'directions',
    findSeat: 'findSeat'
};

const TRAVEL_MODES = {
    WALKING: 'WALKING',
    DRIVING: 'DRIVING',
    TRANSIT: 'TRANSIT',
    BICYCLING: 'BICYCLING'
};

const BREAKPOINTS = {
    DESKTOP: '1024'
};

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

class WayFinding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSection: SECTIONS.directions,
            selectedTravelMode: TRAVEL_MODES.DRIVING,
            isDesktopView: false,
            originAddress: null,
            directionsList: {
                WALKING: null,
                DRIVING: null,
                TRANSIT: null,
                BICYCLING: null
            },
            showRequiredAddressMessage: false,
            isLoading: false,
            showMarker: false,
            markerCoordinates: null,
            selectedSeat: null
        };

        this.setSelectedSection = this.setSelectedSection.bind(this);
        this.setSelectedTravelMode = this.setSelectedTravelMode.bind(this);
        this.updateOrientation = this.updateOrientation.bind(this);
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);

        this.mapElement = null;
    }

    // Life Cycle
    componentDidMount() {
        window.addEventListener('deviceorientation', this.updateOrientation());
    }

    UNSAFE_componentWillUnmount() {
        window.removeEventListener('deviceorientation', this.updateOrientation);
    }

    // Handlers
    handlePlaceChanged(originAddress) {
        this.setState({ originAddress });

        const selectedStadium = STADIUMS_LOCATION[this.props.venue];

        if (originAddress) {
            Object.keys(TRAVEL_MODES).forEach((travelMode) => {
                this.getMapDirections(originAddress, selectedStadium, TRAVEL_MODES[travelMode])
                    .then((directions) => {
                        this.setState({
                            showRequiredAddressMessage: false,
                            directionsList: {
                                ...this.state.directionsList,
                                [travelMode]: directions
                            }
                        });
                    })
                    .catch(() => {
                        this.setState({
                            directionsList: {
                                ...this.state.directionsList,
                                [travelMode]: null
                            }
                        });
                    });
            });
        } else {
            Object.keys(TRAVEL_MODES).forEach((travelMode) => {
                this.setState({
                    showRequiredAddressMessage: true,
                    directionsList: {
                        ...this.state.directionsList,
                        [travelMode]: null
                    }
                });
            });
        }
    }

    // Helpers
    updateOrientation() {
        this.setState({ isDesktopView: window.innerWidth > BREAKPOINTS.DESKTOP });
    }

    setSelectedSection(selectedSection) {
        this.setState({ selectedSection });
    }

    setSelectedTravelMode(selectedTravelMode) {
        this.setState({
            selectedTravelMode,
            showRequiredAddressMessage: !this.state.originAddress
        });
    }

    getMapDirections(origin, destination, travelMode) {
        const DirectionsService = new google.maps.DirectionsService(); // eslint-disable-line

        return new Promise((resolve, reject) => {
            DirectionsService.route({
                origin: new google.maps.LatLng(origin.lat, origin.lng), // eslint-disable-line
                destination: new google.maps.LatLng(destination.lat, destination.lng), // eslint-disable-line
                travelMode
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) { // eslint-disable-line
                    resolve(result);
                } else {
                    reject();
                }
            });
        });
    }

    getCurrentLocation() {
        if ('geolocation' in navigator) {
            this.setState({ isLoading: true });

            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;

                this.handlePlaceChanged({ lat: latitude, lng: longitude });
                this.setState({
                    showRequiredAddressMessage: false,
                    isLoading: false
                });
            }, () => {
                this.handlePlaceChanged(null);
                this.setState({
                    showRequiredAddressMessage: false,
                    isLoading: false
                });
            });
        }
    }

    openGoogleMapsMobile(travelMode) {
        return `https://www.google.com/maps/dir/?api=1&destination=${STADIUMS_LOCATION[this.props.venue].destinationId}&travelmode=${travelMode.toLowerCase()}`;
    }

    setBayInformation(seatInformation) {
        if (seatInformation) {
            const { lat, lng } = seatInformation;

            this.setState({
                showMarker: true,
                markerCoordinates: { lat, lng },
                selectedSeat: seatInformation
            }, () => this.scrollToMap());
        } else {
            this.setState({
                showMarker: false,
                markerCoordinates: null,
                selectedSeat: null
            });
        }
    }

    scrollToMap() {
        if (!this.state.isDesktopView && this.mapElement) {
            this.mapElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // Renders
    renderRouteInformation(travelMode) {
        const direction = this.state.directionsList[travelMode];

        if (direction) {
            const details = direction.routes[0].legs[0];
            const { distance, duration } = details;

            return [
                <span className="duration" key="duration">
                    {duration.text}
                </span>,
                <span className="distance" key="distance">
                    {distance.text}
                </span>
            ];
        }
    }

    renderRequiredMessage() {
        return <span className="required-message">Please add your starting point to view directions.</span>;
    }

    renderDirectionsSection() {
        const { selectedTravelMode, showRequiredAddressMessage, selectedSection, isDesktopView } = this.state;

        const directionsSectionClass = classnames('directions-section', {
            'is-hidden': selectedSection !== SECTIONS.directions
        });

        const description = isDesktopView ? 'Choose a starting point and a way to get to the stadium.' : 'Select your preferred way to get to the stadium.';

        return (
            <div className={directionsSectionClass}>
                <span className="directions-section-description">{description}</span>
                <form className="way-finding-form">
                    <div className="way-finding-form-field">
                        <label className="way-finding-form-label" htmlFor="from">From</label>
                        <PlacesSearchBox
                            loadingElement={<div style={{ height: '100%' }} />}
                            containerElement={<div style={{ height: '400px' }} />}
                            inputClassName="way-finding-form-input"
                            wrapperClassName="way-finding-form-search-box"
                            placeholder="Choose your starting point"
                            places={this.state.originAddress}
                            googleMapURL={googleMapURL}
                            name="from"
                            onPlacesChanged={(originAddress) => this.handlePlaceChanged(originAddress)}
                        />
                        <button className="way-finding-form-location-button" type="button" onClick={() => this.getCurrentLocation()}>
                            Current location
                            <Icon name="marker" />
                        </button>
                    </div>
                    <div className="way-finding-form-field">
                        <label className="way-finding-form-label" htmlFor="to">To</label>
                        <input className="way-finding-form-input" type="text" name="to" id="to" value={STADIUMS_LOCATION[this.props.venue].name} disabled={true} />
                    </div>
                </form>
                {this.state.isDesktopView ?
                    <div className="directions-section-options">
                        <button
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.WALKING ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.WALKING)}>
                            <Icon name="walking" />
                            <span className="travel-mode">On foot</span>
                            {this.renderRouteInformation(TRAVEL_MODES.WALKING)}
                            {showRequiredAddressMessage && selectedTravelMode === TRAVEL_MODES.WALKING &&
                                this.renderRequiredMessage()
                            }
                        </button>
                        <button
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.DRIVING ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.DRIVING)}>
                            <Icon name="driving" />
                            <span className="travel-mode">Driving</span>
                            {this.renderRouteInformation(TRAVEL_MODES.DRIVING)}
                            {showRequiredAddressMessage && selectedTravelMode === TRAVEL_MODES.DRIVING &&
                                this.renderRequiredMessage()
                            }
                        </button>
                        <button
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.TRANSIT ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.TRANSIT)}>
                            <Icon name="transit" />
                            <span className="travel-mode">Public transport</span>
                            {this.renderRouteInformation(TRAVEL_MODES.TRANSIT)}
                            {showRequiredAddressMessage && selectedTravelMode === TRAVEL_MODES.TRANSIT &&
                                this.renderRequiredMessage()
                            }
                        </button>
                        <button
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.BICYCLING ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.BICYCLING)}>
                            <Icon name="bicycling" />
                            <span className="travel-mode">Cycling</span>
                            {this.renderRouteInformation(TRAVEL_MODES.BICYCLING)}
                            {showRequiredAddressMessage && selectedTravelMode === TRAVEL_MODES.BICYCLING &&
                                this.renderRequiredMessage()
                            }
                        </button>
                    </div>
                    : // Mobile anchor to Google Maps
                    <div className="directions-section-options">
                        <a
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.WALKING ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.WALKING)}
                            href={this.openGoogleMapsMobile(TRAVEL_MODES.WALKING)}>
                            <Icon name="walking" />
                            On foot
                        </a>
                        <a
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.DRIVING ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.DRIVING)}
                            href={this.openGoogleMapsMobile(TRAVEL_MODES.DRIVING)}>
                            <Icon name="driving" />
                            Driving
                        </a>
                        <a
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.TRANSIT ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.TRANSIT)}
                            href={this.openGoogleMapsMobile(TRAVEL_MODES.TRANSIT)}>
                            <Icon name="transit" />
                            Public transport
                        </a>
                        <a
                            className={`directions-section-button ${selectedTravelMode === TRAVEL_MODES.BICYCLING ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedTravelMode(TRAVEL_MODES.BICYCLING)}
                            href={this.openGoogleMapsMobile(TRAVEL_MODES.BICYCLING)}>
                            <Icon name="bicycling" />
                            Cycling
                        </a>
                    </div>
                }
            </div>
        );
    }

    renderSeatFinderSection() {
        return (
            <SeatFinder
                isActive={this.state.selectedSection === SECTIONS.findSeat}
                setBayInformation={this.setBayInformation.bind(this)}
                venue={STADIUMS_LOCATION[this.props.venue]}
                selectedSeat={this.state.selectedSeat}
            />
        );
    }

    renderStadiumsOverlay() {
        return (
            <GroundOverlay
                defaultUrl={mtSmartStadiumOverlay}
                defaultBounds={new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(-36.919058, 174.811086), // eslint-disable-line
                    new window.google.maps.LatLng(-36.917630, 174.813555), // eslint-disable-line
                )}
            />
        );
    }

    renderStadiumsGates(gates) {
        const { selectedSeat } = this.state;

        return (
            <div>
                {gates.map(({ icon, lat, lng, name }) => {
                    return (
                        <Marker
                            key={name}
                            icon={{ url: icon }}
                            position={{ lat, lng }}
                            opacity={!selectedSeat || name === selectedSeat.gate.name ? 1 : 0.5} // eslint-disable-line no-magic-numbers
                        />
                    );
                })}
            </div>
        );
    }

    renderCarParkAreas(carParks) {
        const { selectedSeat } = this.state;

        return (
            <div>
                {carParks.map(({ icon, lat, lng, name }) => {
                    return (
                        <Marker
                            key={name}
                            icon={{ url: icon }}
                            position={{ lat, lng }}
                            opacity={!selectedSeat || name === selectedSeat.parking.name ? 1 : 0.5} // eslint-disable-line no-magic-numbers
                        />
                    );
                })}
            </div>
        );
    }

    render() {
        const { selectedSection, directionsList, isLoading, isDesktopView } = this.state;
        const { name, gates, lat, lng } = STADIUMS_LOCATION[this.props.venue];
        // const showFindYourSeat = this.props.show_find_seat === '1';

        const zoomLevel = selectedSection === SECTIONS.findSeat ? 18 : 16; // eslint-disable-line no-magic-numbers

        const wayFindingClass = classnames('way-finding || constrain-width large no-pad', {
            'is-loading': isLoading
        });

        const wayFindMapClass = classnames('way-finding-map', {
            'is-hidden': selectedSection === SECTIONS.directions && !isDesktopView
        });

        return (
            <section className={wayFindingClass}>
                <div className="way-finding-directions">
                    <div className="way-finding-switch">
                        <button
                            className={`way-finding-switch-button ${selectedSection === SECTIONS.directions ? 'is-active' : ''}`}
                            onClick={() => this.setSelectedSection(SECTIONS.directions)}>
                            <Icon name="marker" />
                            Get directions to {name}
                        </button>
                        {/* TODO: Implement layout management through CMS */}
                        {/* {showFindYourSeat &&
                            <buttons
                                className={`way-finding-switch-button ${selectedSection === SECTIONS.findSeat ? 'is-active' : ''}`}
                                onClick={() => this.setSelectedSection(SECTIONS.findSeat)}>
                                <Icon name="seat" />
                                Find your seat
                            </button>
                        } */}
                    </div>
                    {this.renderDirectionsSection()}
                    {this.renderSeatFinderSection()}
                </div>

                <div className={wayFindMapClass} ref={(map) => { this.mapElement = map; }}>
                    {isLoading && <Loader/>}
                    <Map
                        googleMapURL={googleMapURL}
                        loadingElement={<div className="way-finding-map-element" />}
                        containerElement={<div className="way-finding-map-container" />}
                        mapElement={<div className="way-finding-map-element" />}
                        directions={selectedSection === SECTIONS.directions ? directionsList[this.state.selectedTravelMode] : null}
                        options={selectedSection === SECTIONS.directions ? DIRECTIONS_OPTIONS : FIND_SEAT_OPTIONS}
                        zoom={zoomLevel}
                        groundOverlay={selectedSection === SECTIONS.findSeat ? this.renderStadiumsOverlay() : null}
                        showMarker={selectedSection === SECTIONS.findSeat && this.state.showMarker}
                        markerIcon={seatLocationImage}
                        markerCoordinates={this.state.markerCoordinates}
                        lng={lng}
                        lat={lat}
                    >
                        {selectedSection === SECTIONS.findSeat && this.renderStadiumsGates(gates)}
                        {/* TODO: Implement layout management through CMS */}
                        {/* {selectedSection === SECTIONS.findSeat && this.renderCarParkAreas(carParks)} */}
                    </Map>
                </div>
            </section>
        );
    }
}

WayFinding.propTypes = {
    venue: PropTypes.string.isRequired,
    show_find_seat: PropTypes.string.isRequired // eslint-disable-line camelcase
};

export default WayFinding;
