import fieldTypes from './field-types';
import { VALIDATOR_TYPES } from 'app/utilities/form-validation';


export function createInviteeFieldGroup() { // eslint-disable-line require-jsdoc
    return {
        name: {
            label: 'Invitee’s name',
            placeholder: '',
            required: true,
            type: 'text',
            fieldType: fieldTypes.INPUT,
            validatorType: VALIDATOR_TYPES.REQUIRED
        },
        email: {
            label: 'Email address',
            placeholder: '',
            required: true,
            type: 'email',
            fieldType: fieldTypes.INPUT,
            validatorType: VALIDATOR_TYPES.REQUIRED_EMAIL
        }
    };
}

export default {
    name: {
        label: 'Your name',
        placeholder: '',
        required: true,
        type: 'text',
        fieldType: fieldTypes.INPUT,
        validatorType: VALIDATOR_TYPES.REQUIRED
    },
    invitees: {
        fieldType: fieldTypes.FIELD_GROUP_REPEATER,
        defaultValue: [
            createInviteeFieldGroup()
        ]
    }
};
