import { createValidationDataObject } from 'app/utilities/form-validation';
import fieldTypes from './field-types';

const SUBJECT_OPTIONS = [
    {
        value: 'General enquiry',
        label: 'General enquiry'
    },
    {
        value: 'Attending an event',
        label: 'Attending an event'
    },
    {
        value: 'Book a function',
        label: 'Book a function'
    },
    {
        value: 'Lost property',
        label: 'Lost property'
    },
    {
        value: 'Feedback',
        label: 'Feedback'
    }
];

export default [
    {
        name: 'name',
        label: 'Name',
        placeholder: '',
        required: true,
        type: 'text',
        fieldType: fieldTypes.INPUT,
        validator: (name) => {
            if (name) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'email',
        label: 'Email',
        placeholder: '',
        required: true,
        type: 'email',
        fieldType: fieldTypes.INPUT,
        validator: (email) => {
            if (email) {
                // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
                if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) { // eslint-disable-line
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'phone',
        label: 'Phone',
        placeholder: '',
        required: false,
        type: 'text',
        fieldType: fieldTypes.INPUT,
        validator: () => createValidationDataObject()
    },
    {
        name: 'subject',
        label: 'Subject',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.SELECT,
        defaultValue: SUBJECT_OPTIONS[0].value,
        validator: () => createValidationDataObject(),
        options: SUBJECT_OPTIONS
    },
    {
        name: 'company',
        label: 'Company',
        required: false,
        placeholder: '',
        fieldType: fieldTypes.INPUT,
        validator: () => createValidationDataObject(),
        isBookFunctionsField: true
    },
    {
        name: 'capacities',
        label: 'Capacities',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.INPUT,
        validator: (date) => {
            if (date) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        },
        isBookFunctionsField: true
    },
    {
        name: 'interested-venues',
        label: 'Venues I\'m interested in',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.MULTISELECT,
        validator: (venues) => {
            if (venues && venues.length !== 0) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        },
        isBookFunctionsField: true
    },
    {
        name: 'preferred-date',
        label: 'Preferred date',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.INPUT,
        validator: (date) => {
            if (date) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        },
        isBookFunctionsField: true
    },
    {
        name: 'message',
        label: 'Message',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.TEXTAREA,
        validator: (message) => {
            if (message) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    }
];
