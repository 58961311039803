import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const tabNames = ['visitor-information', 'about-the-venue', 'hire-this-venue'];

const Venue = (props) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const {
        blocks,
        hire_venue_blocks: hireVenueBlocks,
        thumbnail,
        name: title,
        main_images: mainImages,
        short_description: description,
        visitor_blocks: visitorBlocks
    } = props.data;

    const setTabState = (tabIndex = 0, hash = '') => {
        setSelectedTabIndex(tabIndex);
        window.history.replaceState(null, null, `#${hash}`);
    };

    const isHireVenueTabActive = () => {
        const { hire_venue_blocks: hireVenueBlocks } = props.data;

        return hireVenueBlocks && hireVenueBlocks.length !== 0;
    };

    const handleSelect = (index) => {
        setTabState(index, tabNames[index]);
    };

    useEffect(() => {
        const urlHash = window.location.hash.substr(1);
        if (!urlHash) {
            // Set to default tab when no hash in the url
            return setSelectedTabIndex(0);
        }
        if (urlHash && tabNames.includes(urlHash)) {
            const urlHashIndex = tabNames.indexOf(urlHash);
            // If there is no content on hire-this-venue tab, reset the tab
            if (urlHash === tabNames[2] && !isHireVenueTabActive()) {
                return setTabState();
            }

            if (urlHashIndex === selectedTabIndex) {
                return;
            }

            return setSelectedTabIndex(urlHashIndex);
        }
        setTabState();
    });

    return (
        <div className="content-page">
            <Banner
                description={description}
                mainImages={mainImages}
                title={title}
                thumbnail={thumbnail}
            />
            <Tabs className="tabs" selectedIndex={selectedTabIndex} onSelect={handleSelect}>
                <TabList className="tabs-tab-list">
                    <Tab className="tabs-tab" selectedClassName="is-active">Visitor information</Tab>
                    <Tab className="tabs-tab" selectedClassName="is-active">About the venue</Tab>
                    {isHireVenueTabActive() && <Tab className="tabs-tab" selectedClassName="is-active">Hire this venue</Tab>}
                </TabList>
                <TabPanel className="tabs-tab-panel">
                    <ContentBlocks data={visitorBlocks} />
                </TabPanel>
                <TabPanel className="tabs-tab-panel">
                    <ContentBlocks data={blocks} />
                </TabPanel>
                {isHireVenueTabActive() && (
                    <TabPanel className="tabs-tab-panel">
                        <ContentBlocks data={hireVenueBlocks} />
                    </TabPanel>
                )}
            </Tabs>
        </div>
    );
};

Venue.propTypes = {
    data: PropTypes.object.isRequired
};

export default Venue;
