import gateA from 'static/images/gate-a.png';
import gateB from 'static/images/gate-b.png';
import gateC from 'static/images/gate-c.png';
import gateD from 'static/images/gate-d.png';
import gateE from 'static/images/gate-e.png';
import gateF from 'static/images/gate-f.png';

export const MT_SMART_STADIUM_GATES = {
    GATE_A: {
        name: 'gateA',
        lat: -36.917397,
        lng: 174.811285,
        icon: gateA,
        title: 'Gate A'
    },
    GATE_B: {
        name: 'gateB',
        lat: -36.917083,
        lng: 174.812431,
        icon: gateB,
        title: 'Gate B'
    },
    GATE_C: {
        name: 'gateC',
        lat: -36.917792,
        lng: 174.814131,
        icon: gateC,
        title: 'Gate C'
    },
    GATE_D: {
        name: 'gateD',
        lat: -36.919058,
        lng: 174.813279,
        icon: gateD,
        title: 'Gate D'
    },
    GATE_E: {
        name: 'gateE',
        lat: -36.918654,
        lng: 174.810079,
        icon: gateE,
        title: 'Gate E'
    },
    GATE_F: {
        name: 'gateF',
        lat: -36.917311,
        lng: 174.810776,
        icon: gateF,
        title: 'Gate F'
    },
};
