import { convertUrlToIframeSource } from 'app/utilities/youtube';
import PropTypes from 'prop-types';
import React from 'react';

function Video(props) {
    const { title, credit, video_url: videoUrl } = props.data;
    const videoSource = convertUrlToIframeSource(videoUrl);

    return (
        <section className="video-content-block">
            <div className="video-content-block-inner || constrain-width no-pad">
                <h3 className="section-header-title">{title}</h3>
                <div className="preserve-aspect-ratio">
                    <div className="preserve-aspect-ratio-media">
                        <iframe
                            title={title}
                            className="video"
                            src={videoSource}
                            allowFullScreen={true}></iframe>
                    </div>
                </div>
                {credit && <p className="video-content-block-credit">{credit}</p>}
            </div>
        </section>
    );
}

Video.propTypes = {
    data: PropTypes.object.isRequired
};

export default Video;
