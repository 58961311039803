import Helmet from 'react-helmet';
import React from 'react';
import { phoneNumber, phoneNumberFormatted } from 'config/contact';

const Error = () => {
    return (
        <main className="page" role="main">
            <Helmet>
                <title>Error 500</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="splash">
                <div className="splash-inner">
                    <h1 className="heading-3">Oops! It looks like we have a server error.</h1>
                    <p>Not to worry though, our team is busy looking into it. If you have an urgent enquiry please contact:<br/> <a href="mailto: info@aucklandstadiums.co.nz">info@aucklandstadiums.co.nz</a> or call <a href={`tel:${phoneNumber}`}>{phoneNumberFormatted}</a></p>
                </div>
            </div>
        </main>
    );
};

export default Error;
