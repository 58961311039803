import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import React from 'react';

function News(props) {
    const {
        blocks,
        thumbnail,
        name: title,
        main_images: mainImages,
        short_description: description
    } = props.data;

    return (
        <div className="content-page">
            <Banner
                description={description}
                mainImages={mainImages}
                title={title}
                thumbnail={thumbnail}
            />
            <ContentBlocks data={blocks} />
        </div>
    );
}

News.propTypes = {
    data: PropTypes.object.isRequired
};

export default News;
