import Banner from 'app/components/partials/banner';
import Helmet from 'react-helmet';
import React from 'react';

export default function NewsletterThankYouPage() { // eslint-disable-line require-jsdoc
    return (
        <main className="page" role="main">
            <Helmet>
                <title>Thanks for signing up</title>
            </Helmet>
            <div className="constrain-width">
                <Banner
                    compact={true}
                    light={true}
                    mainImages={[]}
                    title="Thanks for signing up"
                />
            </div>
            <div className="constrain-width small || section is-last-on-page">
                <p>
                    We’ll be sharing the latest news from Auckland Stadiums. We’ll keep you in the loop with event news, what’s happening at our stadiums, ticket offers and more. You can change your email preferences at any time by following the link at the bottom of our newsletters.
                </p>
            </div>
        </main>
    );
}
