import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

function TextContent(props) {
    const { aside, citation, content, quote } = props.data;

    return (
        <section className="text-content-block || content-section">
            <div className="constrain-width">
                <div className="content-section-inner">
                    <main
                        className="content-section-main || rte-content || rich-editor-content"
                        dangerouslySetInnerHTML={{ __html: formatContent(content) }}></main>
                    { aside && <aside
                        className="content-section-aside || rte-content || rich-editor-content"
                        dangerouslySetInnerHTML={{ __html: formatContent(aside) }}></aside>
                    }
                    { quote && <aside className="content-section-aside">
                        <blockquote>
                            <p className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(quote) }}></p>
                            <p><em>{citation}</em></p>
                        </blockquote>
                    </aside>
                    }
                </div>
            </div>
        </section>
    );
}

TextContent.propTypes = {
    data: PropTypes.object.isRequired
};

export default TextContent;
