import FEATURED_STADIUMS from 'config/featured-stadiums';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import React from 'react';

// eslint-disable-next-line require-jsdoc
function renderStadium(stadium, index) {
    const { label, pageLink, directionsLink, thumbnailImageUrl, detail, socials } = stadium;

    return (
        <article className="new-featured-tile" key={index}>
            <Link
                className="new-featured-tile-inner || image-overlay"
                to={pageLink}
                style={{ backgroundImage: `url(${thumbnailImageUrl})` }}
            >
                <div className="new-featured-tile-heading-group">
                    <h2 className="new-featured-tile-heading-group-title">{label}</h2>
                    <h6 className="new-featured-tile-heading-group-detail">{detail}</h6>
                </div>
            </Link>
            <a
                className="new-featured-tile-directions"
                href={directionsLink}
                /* ↓ Tracking attributes */
                data-id="micro_conversion"
                data-type="engaging_content"
                data-action="directions_click"
                data-text="Get directions"
                /* ↑ Tracking attributes */
            >
                <Icon name="location" />Get directions
            </a>
            <div className="new-featured-tile-socials" >
                {socials.map((socialItem, index) => (
                    <a className="new-featured-tile-socials-item" key={index} href={socialItem.link}>
                        <Icon name={socialItem.icon} />
                    </a>
                )) }
            </div>
        </article>
    );
}

// eslint-disable-next-line require-jsdoc
function NewFeaturedStadiums() {
    return (
        <section className="new-featured-block">
            <div className="new-featured-block-inner">
                {/* Need this part to change position of the featured stadiums array
                    TODO: delete it when deploy to prod
                */}
                {[FEATURED_STADIUMS[1], FEATURED_STADIUMS[0], FEATURED_STADIUMS[2]].map(renderStadium)}
            </div>
        </section>
    );
}

export default NewFeaturedStadiums;
