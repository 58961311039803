import AUCKLAND_UNLIMITED_LOGO from 'static/images/logos/auckland-unlimited-logo-white.svg';
import { CLICK_TRACKING_ATTRIBUTES } from 'config/social';
import FeaturedStadiums from 'app/components/partials/featured-stadiums';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import Logo from 'app/components/partials/logo';
import React from 'react';
import WomenRefuge from './women-refuge';
import { phoneNumber, phoneNumberFormatted } from 'config/contact';

const NewFooter = () => {
    const copyrightYear = new Date().getFullYear();

    return (
        <footer className="footer constrain-width x-large no-pad">
            <FeaturedStadiums />
            <div className="footer-primary">
                <div className="constrain-width footer-inner">
                    <div className="footer-subscription">
                        <div className="general-enquiries">
                            <h5 className="heading">
                                General Enquiries
                            </h5>
                            <div className="contact-info">
                                <div className="contact-info-label">
                                    <p>Phone:</p>
                                </div>
                                <div
                                    className="contact-info-main"
                                >
                                    <p><a
                                        href={`tel:${phoneNumber}`}
                                        /* ↓ Tracking attributes */
                                        data-id="macro_conversion"
                                        data-type="lead"
                                        data-action="phone_click"
                                        data-text={phoneNumberFormatted}
                                        /* ↑ Tracking attributes */
                                    >{phoneNumberFormatted}</a></p>
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="contact-info-label">
                                    <p>Email:</p>
                                </div>
                                <div
                                    className="contact-info-main"
                                >
                                    <p><a
                                        href="mailto:info@aucklandstadiums.co.nz"
                                        /* ↓ Tracking attributes */
                                        data-id="macro_conversion"
                                        data-type="lead"
                                        data-action="email_click"
                                        data-text="info@aucklandstadiums.co.nz"
                                        /* ↑ Tracking attributes */
                                    >info@aucklandstadiums.co.nz</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="subscription">
                            <form className="newsletter || form" action="/newsletter/preferences" method="GET">
                                <fieldset className="form-inline">
                                    <h5 className="heading"><label htmlFor="email">Be the first to know</label></h5>
                                    <div className="field-group">
                                        <input
                                            name="email"
                                            id="email"
                                            className="form-field || input"
                                            type="email"
                                            autoComplete="email"
                                            autoCorrect="off"
                                            autoCapitalize="off"
                                            required
                                        />
                                        <button type="submit" className="button primary">Submit</button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    <div className="footer-info">
                        <Link className="footer-info-logo" to="/">
                            <Logo
                                name="auckland-stadiums-logo-light"
                                title="Auckland Stadiums"
                                type="light"
                                svgAttributes={{
                                    height: 102,
                                    width: 200,
                                }}
                            />
                        </Link>
                        <div className="footer-info-socials">
                            <h5 className="heading">Follow Auckland Stadiums on</h5>
                            <div className="social-links">
                                <a
                                    className="link"
                                    href="https://www.instagram.com/auckland_stadiums/"
                                    /* ↓ Tracking attributes */
                                    {...CLICK_TRACKING_ATTRIBUTES}
                                    data-text="Instagram (Auckland Stadiums)"
                                    /* ↑ Tracking attributes */
                                >
                                    <Icon name="instagram" />
                                </a>
                                <a
                                    className="link"
                                    href="https://www.linkedin.com/company/auckland-stadiums/"
                                    /* ↓ Tracking attributes */
                                    {...CLICK_TRACKING_ATTRIBUTES}
                                    data-text="LinkedIn (Auckland Stadiums)"
                                    /* ↑ Tracking attributes */
                                >
                                    <Icon name="linkedin" />
                                </a>
                            </div>
                        </div>
                        <a className="auckland-unlimited-logo" href="https://www.aucklandunlimited.com/" title="View the Tātaki Auckland Unlimited website">
                            <img
                                className="logo"
                                width="200"
                                src={AUCKLAND_UNLIMITED_LOGO}
                                alt="Tātaki Auckland Unlimited Logo"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-secondary">
                <div className="constrain-width footer-outro">
                    <p>&copy; {copyrightYear} Auckland Stadiums <span className="footer-text-spacer">|</span><br className="footer-text-break" />
                        <Link to="/terms-and-privacy-policy">Terms and Privacy Policy</Link><span className="footer-text-spacer">|</span><br className="footer-text-break" />
                        <Link to="/visitor-info/conditions-of-entry">Conditions of Entry</Link>
                    </p>
                    <WomenRefuge />
                </div>
            </div>
        </footer>
    );
};

export default NewFooter;
