import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

// Event Reminder Actions
export const PASS_EVENT_REMINDER_ACTION_TO_THE_API = 'rfa-stadiums-website/event-reminder/PASS_EVENT_REMINDER_ACTION_TO_THE_API';

// Event Reminder Action Creators
export const passEventReminderActionToTheApiAction = (token) => ({
    type: PASS_EVENT_REMINDER_ACTION_TO_THE_API,
    token
});

// Epic creators
export const createEventReminderEpic = (eventReminderActionEndpoint) => { // eslint-disable-line require-jsdoc
    return (action$) => action$.pipe(
        ofType(PASS_EVENT_REMINDER_ACTION_TO_THE_API),
        mergeMap(({ token }) => {
            return fetch(eventReminderActionEndpoint, null, {
                method: 'POST',
                body: JSON.stringify({ token })
            })
                .then((response) => {
                    if (response.ok) {
                        return 'Thank you, your request has been processed successfully.';
                    }

                    return 'Thank you, your request has been processed successfully.';
                })
                .catch(() => {
                    return 'Failed to perform the operation. Please try again.';
                })
                .then((message) => {
                    // HACK: quick fix for the system unsubscribe message self-closing.
                    setTimeout(() => {
                        window.alert(message);
                    }, 2000); // eslint-disable-line no-magic-numbers

                    // HACK: quick fix for the pre-router middleware issue.
                    return {
                        type: 'type'
                    };
                });
        })
    );
};

// Epics
const eventReminderEpic = createEventReminderEpic(
    ENDPOINTS.EVENT_REMINDER_ACTION
);


export const epics = combineEpics(
    eventReminderEpic
);
