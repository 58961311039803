import defaultBannerImage from 'static/images/default-page-banner.jpg';
import PropTypes from 'prop-types';
import React from 'react';


function PageHeader({ images, introduction, title, type }) {
    // Use the first image only as the background
    const image = images && images.length ? images[0] : defaultBannerImage;
    const style = image ? { backgroundImage: `url(${image})` } : {};

    return (
        <header
            role="heading"
            className={`page-header ${type === 'primary' ? 'primary' : ''} || image-overlay`}
            style={style}
        >
            <div className="page-header-inner">
                <h1 className="page-header-title || constrain-width">{title}</h1>
                {introduction && <p className="constrain-width">{introduction}</p>}
            </div>
        </header>
    );
}

PageHeader.propTypes = {
    images: PropTypes.array,
    introduction: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string
};

export default PageHeader;
