/* eslint-disable require-jsdoc */
import React from 'react';
import { phoneNumber, phoneNumberFormatted } from 'config/contact';

function GetInTouch() {
    return (
        <div id="get-in-touch">
            <h3 className="pre-footer-title">Get in Touch</h3>
            <div className="pre-footer-main">
                <div className="labeled-content">
                    <div className="labeled-content-label">
                        <p>Phone:</p>
                    </div>
                    <div
                        className="labeled-content-main"
                        content={phoneNumber}
                    >
                        <p><a
                            href={`tel:${phoneNumber}`}
                            /* ↓ Tracking attributes */
                            data-id="macro_conversion"
                            data-type="lead"
                            data-action="phone_click"
                            data-text={phoneNumberFormatted}
                            /* ↑ Tracking attributes */
                        >{phoneNumberFormatted}</a></p>
                    </div>
                </div>
                <div className="labeled-content">
                    <div className="labeled-content-label">
                        <p>Email:</p>
                    </div>
                    <div
                        className="labeled-content-main"
                    >
                        <p><a
                            href="mailto:info@aucklandstadiums.co.nz"
                            /* ↓ Tracking attributes */
                            data-id="macro_conversion"
                            data-type="lead"
                            data-action="email_click"
                            data-text="info@aucklandstadiums.co.nz"
                            /* ↑ Tracking attributes */
                        >info@aucklandstadiums.co.nz</a></p>
                    </div>
                </div>
                <address>
                    <div className="labeled-content">
                        <div className="labeled-content-label">
                            <p>Address:</p>
                        </div>
                        <div className="labeled-content-main">
                            <p>
                                Auckland Stadiums Head Office<br/>
                                Go Media Stadium<br/>
                                2 Beasley Ave<br/>
                                Penrose<br />
                                Auckland 1061<br/>
                                New Zealand
                            </p>
                        </div>
                    </div>
                    <div className="labeled-content">
                        <div className="labeled-content-label">
                            <p>Postal:</p>
                        </div>
                        <div className="labeled-content-main">
                            <p>
                                PO Box 5749<br/>
                                Victoria Street West<br/>
                                Auckland 1142
                            </p>
                        </div>
                    </div>
                </address>
            </div>
        </div>
    );
}

export default GetInTouch;
