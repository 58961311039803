import { MT_SMART_STADIUM } from 'config/way-finding-seat-coordinates';
import { MT_SMART_STADIUM_CAR_PARKS } from 'config/venue-car-parks';
import { MT_SMART_STADIUM_GATES } from 'config/venue-gates';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCgQvwk9pX4n1LUmB_jquj75ZyAqVJyT0k';


export const STADIUMS_LOCATION = {
    MT_SMART_STADIUM: {
        lat: -36.9183393,
        lng: 174.8124974,
        destinationId: 'Mt+Smart+Stadium',
        name: 'Go Media Stadium',
        seatCoordinates: MT_SMART_STADIUM,
        minSeatNumber: '1',
        maxSeatNumber: '59',
        gates: Object.keys(MT_SMART_STADIUM_GATES).map((gate) => {
            return MT_SMART_STADIUM_GATES[gate];
        }),
        carParks: Object.keys(MT_SMART_STADIUM_CAR_PARKS).map((parking) => {
            return MT_SMART_STADIUM_CAR_PARKS[parking];
        })
    },
    WESTERN_SPRINGS_STADIUM: {
        lat: -36.865039,
        lng: 174.725999,
        destinationId: 'Western+Springs+Stadium',
        name: 'Western Springs Stadium'
    },
    NORTH_HARBOUR_STADIUM: {
        lat: -36.7268156,
        lng: 174.6993844,
        destinationId: 'North+Harbour+Stadium',
        name: 'North Harbour Stadium'
    },
};

export const DIRECTIONS_OPTIONS = {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    fullscreenControl: false,
    streetViewControl: false,
    styles: []
};

export const FIND_SEAT_OPTIONS = {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    fullscreenControl: false,
    streetViewControl: false,
    styles: [
        {
            'stylers': [
                {
                    'saturation': 5
                },
                {
                    'lightness': -5
                }
            ]
        },
        {
            'elementType': 'geometry',
            'stylers': [
                {
                    'saturation': -100
                },
                {
                    'lightness': -10
                }
            ]
        },
        {
            'elementType': 'labels',
            'stylers': [
                {
                    'lightness': 5
                },
                {
                    'visibility': 'on'
                }
            ]
        },
        {
            'elementType': 'labels.text',
            'stylers': [
                {
                    'visibility': 'on'
                }
            ]
        },
        {
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#2a0018'
                }
            ]
        },
        {
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'color': '#8f9992'
                },
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'landscape',
            'stylers': [
                {
                    'color': '#85a084'
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'stylers': [
                {
                    'color': '#85a084'
                }
            ]
        },
        {
            'featureType': 'poi.place_of_worship',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'poi.school',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'poi.sports_complex',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'road',
            'stylers': [
                {
                    'color': '#85a084'
                },
                {
                    'lightness': 20
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'labels.text',
            'stylers': [
                {
                    'color': '#000000'
                }
            ]
        }
    ]
};
