import { formatDate } from 'app/utilities/date-time';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'app/utilities/truncate';

const DESCRIPTION_CHARACTER_LIMIT = 80;
const TITLE_CHARACTER_LIMIT = 60;

const SearchGrids = ({ items }) => {
    const renderTile = (item, index) => {
        const {
            name,
            thumbnail,
            short_description: description,
            venues,
            slug
        } = item.attributes;

        const { type } = item;

        let formattedDate, parent;

        parent = item.attributes.parent;
        if (type === 'events') {
            const { start_date: startDate, end_date: endDate } = item.attributes;

            formattedDate = formatDate(startDate, endDate);

            parent = 'event';
        }
        if (type === 'venues') {
            parent = 'our-venues';
        }
        if (type === 'news') {
            parent = 'news';
        }


        const url = parent ? `/${parent}/${slug}` : `/${slug}`;

        return (
            <article className="search-grids-tile new-tile-event" key={index}>
                <Link to={url} className="new-tile-inner">
                    <div className="new-tile-image" style={{ backgroundImage: `url(${thumbnail})` }} />
                    <div className="new-tile-description">
                        {type === 'events' && formatDate && <p className="event-date">{formattedDate}</p>}
                        <h5>{truncate(name, TITLE_CHARACTER_LIMIT)}</h5>
                        {description && <p>{truncate(description, DESCRIPTION_CHARACTER_LIMIT)}</p>}
                        {type === 'events' && venues && (
                            <p className="location">
                                <Icon name="location" />{venues}
                            </p>
                        )}
                    </div>
                </Link>
            </article>
        );
    };

    return (
        <section>
            <div className="constrain-width">
                <div className="tile-block-inner">
                    {items.map((item, index) => {
                        return renderTile(item, index);
                    })}
                </div>
            </div>
        </section>
    );
};

SearchGrids.propTypes = {
    items: PropTypes.array.isRequired,
};

export default SearchGrids;
