/* eslint-disable camelcase */

import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const BYTE_BASE = 1024;
const DECIMAL_PLACES = 1;
const KILO_BYTE = 1024;
const MEGA_BYTE = KILO_BYTE * BYTE_BASE;
const GIGA_BYTE = MEGA_BYTE * BYTE_BASE;

class FileDownload extends React.Component {
    get fileInfo() {
        const bytes = this.props.filesize;
        const extension = this.props.extension.toUpperCase();
        const extensionText = `${extension} file`;

        if ((bytes >= KILO_BYTE) && (bytes < MEGA_BYTE)) {
            return `${(bytes / KILO_BYTE).toFixed(DECIMAL_PLACES)} KB ${extensionText}`;
        } else if ((bytes >= MEGA_BYTE) && (bytes < GIGA_BYTE)) {
            return `${(bytes / MEGA_BYTE).toFixed(DECIMAL_PLACES)} MB ${extensionText}`;
        } else if (bytes > GIGA_BYTE) {
            return `${(bytes / GIGA_BYTE).toFixed(DECIMAL_PLACES)} GB ${extensionText}`;
        }

        return `${bytes} B ${extensionText}`;
    }

    render() {
        const { display_name, file, filename } = this.props;
        const fileInfo = this.fileInfo;

        return (
            <section className="file-content-block || content-section">
                <div className="constrain-width">
                    <div className="file-download">
                        <div className="file-download-details">
                            <Icon name="stadium" />
                            <h5 className="file-download-title">{display_name || filename}</h5>
                            <p className="file-download-text">{fileInfo}</p>
                        </div>
                        <a
                            className="button primary"
                            href={file}
                            download={file}
                            target="_blank"
                            /* ↓ Tracking attributes */
                            data-id="micro_conversion"
                            data-type="engaging_content"
                            data-action="pdf_download"
                            data-text={filename}
                            /* ↑ Tracking attributes */
                        >Download</a>
                    </div>
                </div>
            </section>
        );
    }
}

FileDownload.propTypes = {
    display_name: PropTypes.string,
    extension: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    filesize: PropTypes.string.isRequired
};

export default FileDownload;
