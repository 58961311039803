import { connect } from 'react-redux';
import Header from './header';
import selectStatus from 'app/selectors/status';
import {
    fetchNotificationsAction,
    toggleNotificationsAction
} from 'app/ducks/notifications';
import {
    selectIsActive,
    selectNotifications
} from 'app/selectors/notifications';

function mapStateToProps(state) { // eslint-disable-line require-jsdoc
    return {
        status: selectStatus(state),
        notifications: selectNotifications(state),
        isNotificationsActive: selectIsActive(state)
    };
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        fetchNotifications: () => dispatch(fetchNotificationsAction()),
        toggleNotifications: () => dispatch(toggleNotificationsAction())
    };
}

const VisibleHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default VisibleHeader;
