import Data from 'app/components/partials/data';
import PropTypes from 'prop-types';
import React from 'react';

function PagebreakQuote({ cite, file, quote, theme }) {
    return (
        <Data title="Content Block: Pagebreak Quote" data={{ cite, file, quote, theme }} />
    );
}

PagebreakQuote.propTypes = {
    cite: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired
};

PagebreakQuote.defaultProps = {
    theme: 'blue'
};

export default PagebreakQuote;
