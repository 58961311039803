import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import Helmet from 'react-helmet';
import Icon from 'app/components/partials/icon';
import InviteForm from 'app/components/partials/invite-form.container';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const Performance = (props) => {
    const { data } = props;
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const {
        blocks,
        event,
        starts_at: startsAt,
        venue_name: venueName
    } = data;

    const startsAtMoment = moment(startsAt);
    const startsAtMomentUtc = moment.utc(startsAt);

    const { name } = event;

    const canonicalUrl = `https://www.aucklandstadiums.co.nz${location.pathname}`;
    const details = `More information: ${canonicalUrl}`;
    const addToCalendarHref = `https://www.google.com/calendar/event?action=TEMPLATE&text=${encodeURIComponent(name)}&dates=${encodeURIComponent(startsAtMomentUtc.format('YYYYMMDD[T]HHmmss[Z]'))}/${encodeURIComponent(startsAtMomentUtc.format('YYYYMMDD[T]HHmmss[Z]'))}&location=${encodeURIComponent(venueName)}&details=${encodeURIComponent(details)}`;

    useEffect(() => {
        const { passEventReminderActionToTheApi } = props;
        if (searchParams.get('a')) passEventReminderActionToTheApi(searchParams.get('a'));
    }, []);

    return (
        <div className="content-page">
            <Helmet>
                <title>{name}</title>
            </Helmet>
            <Banner
                compact={true}
                description={(
                    <div className="performance-intro">
                        <div className="performance-intro-where-and-when">
                            <Icon name="waypoint" /> {venueName} - {startsAtMoment.format('dddd D MMM YYYY')}
                        </div>
                        <a
                            className="performance-intro-calendar-link"
                            href={addToCalendarHref}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon name="calendar" /> <span>Add to calendar</span>
                        </a>
                    </div>
                )}
                light={true}
                mainImages={[]}
                title={`You’re going to ${name}!`}
            />
            <ContentBlocks data={blocks} />
            <InviteForm pathname={location.pathname} eventName={name} />
        </div>
    );
};

Performance.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    passEventReminderActionToTheApi: PropTypes.func.isRequired
};

export default Performance;
