import ContactUs from './contact-us';
import Event from './event';
import Home from './home';
import News from './news';
import Page from './page';
import Performance from './performance.container';
import PropTypes from 'prop-types';
import React from 'react';
import Venue from './venue';

function Template({ data, type }) {
    // TODO: This relies on slug determining the page type


    // events???
    switch (type) {
        case 'home':
            return <Home data={data} />;
        case 'contact-us':
            return <ContactUs data={data} />;
        case 'event':
            return <Event data={data} />;
        case 'news':
            return <News data={data} />;
        case 'performance':
            return <Performance data={data} />;
        case 'venue':
            return <Venue data={data} />;
        default:
            return <Page data={data} />;
    }
}

Template.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired
};

export default Template;
