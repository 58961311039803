import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import EnquiryForm from 'app/components/partials/enquiry-form.container';
import GetInTouch from 'app/components/partials/get-in-touch';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line require-jsdoc
function ContactUs(props) {
    const {
        blocks,
        thumbnail,
        name: title,
        main_images: mainImages,
        short_description: description
    } = props.data;

    return (
        <div className="content-page">
            <Banner
                description={description}
                mainImages={mainImages}
                title={title}
                thumbnail={thumbnail}
            />
            <ContentBlocks data={blocks} />
            <div className="contact-us-section || constrain-width">
                <div className="get-in-touch">
                    <GetInTouch />
                </div>
                <div className="enquiry">
                    <EnquiryForm />
                </div>
            </div>
        </div>
    );
}

ContactUs.propTypes = {
    data: PropTypes.object.isRequired
};

export default ContactUs;
