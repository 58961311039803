import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';

const NotFound = () => {
    return (
        <main className="page" role="main">
            <Helmet>
                <title>404 Page not found</title>
            </Helmet>
            <div className="splash">
                <div className="splash-inner">
                    <h1 className="heading-3">Sorry, we couldn&apos;t find that page.</h1>
                    <p>Please visit the homepage or <Link to="/about-us/contact-us">get in touch</Link> with us.</p>
                    <Link to="/" className="button primary large">Go to homepage</Link>
                </div>
            </div>
        </main>
    );
}

export default NotFound;
