import accessible from 'static/icons/accessible-seating.svg';
import bag from 'static/icons/bag-restrictions.svg';
import ContentBlocks from 'app/components/content-blocks/index';
import defaultBannerImage from 'static/images/default-page-banner.jpg';
import EventHeroCarousel from '../partials/event-hero-carousel';
import eventStructuredData from 'app/components/partials/event-structured-data';
import { formatDate } from 'app/utilities/date-time';
import noPhoto from 'static/icons/no-photography.svg';
import openTimes from 'static/icons/open-times.svg';
import PropTypes from 'prop-types';
import publicTransport from 'static/icons/public-transport.svg';
import supportingActs from 'static/icons/supporting-acts.svg';
import TextContent from 'app/components/content-blocks/text-content';
import React, { Component } from 'react';

const DESKTOP_SCREEN_WIDTH = 1024;

class Event extends Component { // eslint-disable-line require-jsdoc
    constructor(props) {
        super(props);

        this.state = {
            isDesktopView: false
        };
    }

    // Lifecycle
    componentDidMount() {
        this.setState({ isDesktopView: window.innerWidth > DESKTOP_SCREEN_WIDTH });
    }

    render() {
        const {
            blocks,
            description,
            venues,
            name: title,
            gates_open_at: gateOpenAt,
            photo_restrictions: photoRestrictions,
            support_acts: supportActs,
            accessible_seating: accessibleAvailable,
            free_transport: freeTransport,
            bag_restrictions: bagRestrictions,
            start_date: startDate,
            end_date: endDate,
            hero_image: heroCarousel,
            buy_ticket_link: buyTicketLink
        } = this.props.data;

        const eventSummary = `
            <div class="event">
                <div class="event-summary">
                    <h5 class="event-summary-title">Event Summary</h5>
                    <ul>
                        ${gateOpenAt ? `<li style='background-image: url(${openTimes})'>${gateOpenAt}</li>` : ''}
                        ${supportActs ? `<li style='background-image: url(${supportingActs})'>${supportActs}</li>` : ''}
                        ${accessibleAvailable ? `<li style='background-image: url(${accessible})'>Accessible seating available</li>` : ''}
                        ${photoRestrictions ? `<li style='background-image: url(${noPhoto})'>${photoRestrictions}</li>` : ''}
                        ${freeTransport ? `<li style='background-image: url(${publicTransport})'>Free travel on public transport</li>` : ''}
                        ${bagRestrictions ? `<li style='background-image: url(${bag})'>Bag size restrictions apply</li>` : ''}
                    </ul>
                </div>
            </div>
        `;

        const formattedDate = startDate && formatDate(startDate, endDate);

        const { images, video, description: heroDescription } = heroCarousel;
        const { desktop: desktopImages, mobile: mobileImages } = images;
        const { isDesktopView } = this.state;

        let heroImages = isDesktopView ? desktopImages : mobileImages;

        if (!heroImages || heroImages.lenght === 0) {
            heroImages = [defaultBannerImage];
        }

        return (
            <div className="content-page">
                <EventHeroCarousel
                    images={heroImages}
                    video={isDesktopView && video ? video : null}
                    title={title}
                    description={heroDescription}
                    date={formattedDate}
                    venue={venues}
                    buyTicketLink={buyTicketLink}
                />
                <TextContent data={{
                    content: description,
                    aside: eventSummary
                }} />
                <ContentBlocks data={blocks} />
                {eventStructuredData(this.props.data)}
            </div>
        );
    }
}

Event.propTypes = {
    data: PropTypes.object.isRequired
};

export default Event;
