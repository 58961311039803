export const INITIAL_STATE = {
    isDisplay: true
};

// Actions
export const TOGGLE_STRIP = 'rfa-stadiums-website/strip/TOGGLE_STRIP';

// Action Creators
export const toggleStripAction = () => ({
    type: TOGGLE_STRIP
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_STRIP:
            return { ...state, isDisplay: !state.isDisplay };
        default:
            return state;
    }
};
