// TODO: Update social links
const socialLinks = {
    facebook: 'https://www.facebook.com',
    instagram: 'https://www.instagram.com',
    twitter: 'https://twitter.com',
    youtube: 'https://www.youtube.com'
};

export default socialLinks;

export const CLICK_TRACKING_ATTRIBUTES = {
    'data-id': 'micro_conversion',
    'data-type': 'social',
    'data-action': 'social_clicks'
};
