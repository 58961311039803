/* eslint-disable jsx-a11y/anchor-is-valid */
import CallToAction from 'app/components/content-blocks/call-to-action';
import ContentSet from 'app/components/content-blocks/content-set';
import Faq from 'app/components/content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Gallery from 'app/components/content-blocks/gallery';
import Helmet from 'react-helmet';
import HeroCarousel from 'app/components/partials/hero-carousel';
import Icon from 'app/components/partials/icon';
import Logo from 'app/components/partials/logo';
import MOCK_DATA from 'config/mock-data';
import PageHeader from 'app/components/partials/page-header';
import Section from 'app/components/partials/style-guide-section';
import StacklaWidget from 'app/components/content-blocks/stackla-widget';
import TextContent from 'app/components/content-blocks/text-content';
import Video from 'app/components/content-blocks/video';
import React, { Component } from 'react';

/**
 * Style Guide Page
 */
class StyleGuidePage extends Component {
    render() {
        return (
            <main className="style-guide" role="main">
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <PageHeader
                    title="Style Guide"
                    introduction="A working site style guide built to manage design and code quality control."
                />
                <Section title="Colours" type="constrain">
                    <div className="style-guide-swatches" role="group">
                        <div className="swatch colour-primary">
                            <div className="swatch-inner">
                                <p className="swatch-title">Primary</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation tint-1"></span>
                                    <span className="swatch-variation shade-1"></span>
                                </div>
                            </div>
                        </div>
                        <div className="swatch colour-secondary">
                            <div className="swatch-inner">
                                <p className="swatch-title">Secondary</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation tint-1"></span>
                                    <span className="swatch-variation shade-1"></span>
                                </div>
                            </div>
                        </div>
                        <div className="swatch colour-small colour-dark">
                            <div className="swatch-inner">
                                <p className="swatch-title">Dark</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation tint-1"></span>
                                    <span className="swatch-variation tint-2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="swatch small outline colour-light">
                            <div className="swatch-inner">
                                <p className="swatch-title">Light</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation shade-1"></span>
                                    <span className="swatch-variation shade-2"></span>
                                    <span className="swatch-variation shade-3"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>

                <Section title="Typography" type="constrain">
                    {/* Heading Styles: h1, h2, h3, h4, h5, h6 */}
                    <h1>Heading 1</h1>
                    <h2>Heading 2</h2>
                    <h3>Heading 3</h3>
                    <h4>Heading 4</h4>
                    <h5>Heading 5</h5>
                    <h6>Heading 6</h6>

                    {/* Paragraph: strong, em, a */}
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                    <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                </Section>

                <Section title="Lists" type="constrain">
                    {/* Lists: strong, em, a */}
                    <h4>Unordered</h4>
                    <ul>
                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                    </ul>

                    <h4>Ordered</h4>
                    <ol>
                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                    </ol>
                </Section>

                <Section title="Line" type="constrain">
                    <h4>Default</h4>
                    <hr />

                    <h4>Small</h4>
                    <hr className="small" />
                </Section>

                {/* TODO: Implement blockquote when used in design
                <Section title="Blockquote">
                    <blockquote>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                        <p><em>Authors Name, Company</em></p>
                    </blockquote>
                </Section>
                */}

                <Section title="Logos" type="constrain">
                    <div className="style-guide-logos">
                        {/* Auckland Stadiums */}
                        <Logo
                            name="auckland-stadiums-logo"
                            title="Auckland Stadiums"
                        />
                    </div>
                </Section>

                <Section title="Icons" type="constrain">
                    <div className="style-guide-icons">
                        <Icon name="menu" />
                        <Icon name="cross" />
                        <Icon name="stadium" />
                        <Icon name="instagram" />
                        <Icon name="arrow-down" />
                        <Icon name="waypoint" />
                        <Icon name="calendar" />
                        <Icon name="bell" />
                    </div>
                </Section>

                <Section title="Buttons" type="constrain">
                    {/* Buttons: primary, secondary, disabled */}
                    <h4 className="style-guide-sub-heading">Default</h4>
                    <div role="group" className="style-guide-button-group">
                        <a className="button" href="#">Default</a>
                        <a className="button is-active" href="#">Active</a>
                        <a className="button is-disabled" href="#">Disabled</a>
                    </div>

                    <h4 className="style-guide-sub-heading">Primary</h4>
                    <div role="group" className="style-guide-button-group">
                        <a className="button primary" href="#">Primary</a>
                        <a className="button primary is-active" href="#">Active</a>
                        <a className="button primary is-disabled" href="#">Disabled</a>
                    </div>

                    {/* Action Button */}
                    <h4 className="style-guide-sub-heading">Action</h4>
                    <div role="group" className="style-guide-button-group">
                        <button className="action-button">
                            <span className="action-button-inner">
                                <span className="action-button-label">Action Button Label (Left)</span>
                                <Icon name="menu" />
                            </span>
                        </button>
                        <button className="action-button">
                            <span className="action-button-inner">
                                <Icon name="menu" />
                                <span className="action-button-label">Action Button Label (Right)</span>
                            </span>
                        </button>
                    </div>
                </Section>

                <Section title="Constraint Widths" type="constrain">
                    <div className="style-guide-lines">
                        <p className="style-guide-line || constrain-width no-pad x-large">X Large</p>
                        <p className="style-guide-line || constrain-width no-pad large">Large</p>
                        <p className="style-guide-line || constrain-width no-pad">Default</p>
                        <p className="style-guide-line || constrain-width no-pad medium">Medium</p>
                        <p className="style-guide-line || constrain-width no-pad small">Small</p>
                    </div>
                </Section>

                <Section title="Text Content">
                    <TextContent {...MOCK_DATA.TEXT_PROPS} />

                    <TextContent {...MOCK_DATA.TEXT_ASIDE_PROPS} />

                    <TextContent {...MOCK_DATA.TEXT_QUOTE_PROPS} />
                </Section>

                <Section title="Call to Action" description="The image size should be 1920px x 1280px (no crop: 1400px x 600px) (landscape, HQ). Max size is 500kb.">
                    <CallToAction {...MOCK_DATA.CALL_TO_ACTION_PROPS} />
                </Section>

                <Section title="File download">
                    <FileDownload
                        extension="jpg"
                        filename="north-harbour-stadium-layout.jpg"
                        filesize="246678"
                        file="north-harbour-stadium-layout.jpg"/>

                    <FileDownload
                        extension="jpg"
                        filename="north-harbour-stadium-layout-with-a-really-long-unnecessary-name-slash-title.jpg"
                        filesize="465789461"
                        file="north-harbour-stadium-layout-with-a-really-long-unnecessary-name-slash-title.jpg"/>
                </Section>

                <Section title="FAQs">
                    <Faq {...MOCK_DATA.FAQ_PROPS} />
                </Section>

                <Section title="Content Set" description="The image size should be 640px x 960px (portrait, MQ).">
                    <ContentSet {...MOCK_DATA.CONTENT_SET_PROPS} />
                </Section>

                <Section title="Video">
                    <Video {...MOCK_DATA.VIDEO_PROPS} />
                </Section>

                <Section title="Image gallery" description="The image size should be 1920px x 1280px (no crop: 1400px x 600px) (landscape, HQ). Max size is 500kb">
                    <Gallery {...MOCK_DATA.GALLERY_PROPS} />
                </Section>

                <Section title="Hero Carousel" description="The image should be 1920px x 1280px (landscape, HQ).">
                    <HeroCarousel {...MOCK_DATA.HERO_CAROUSEL_PROPS} />
                </Section>

                <Section title="Form Fields">
                    <div className="constrain-width">
                        <form className="form" noValidate={true}>
                            <div className="form-fieldset">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input className="form-field || input" id="name" type="text" placeholder="e.g John Mayer" />
                            </div>
                            <div className="form-fieldset is-required">
                                <label className="form-label" htmlFor="age">Age</label>
                                <span className="form-validation">This is a required field</span>
                                <input className="form-field || input is-invalid" id="age" type="number" placeholder="e.g 23" required={true} />
                            </div>
                            <div className="form-fieldset">
                                <label className="form-label" htmlFor="disabled">Disabled</label>
                                <input className="form-field || input" id="disabled" type="text" placeholder="Disabled" disabled={true} />
                            </div>
                            <div className="form-fieldset">
                                <label className="form-label" htmlFor="select">Select</label>
                                <select className="form-field || input || select" id="select">
                                    <option>First option</option>
                                    <option>Second option</option>
                                    <option>Third option</option>
                                </select>
                            </div>
                            <div className="form-fieldset">
                                <label className="form-label" htmlFor="textArea">Select</label>
                                <textarea className="form-field || input || text-area" id="textArea" type="text" />
                            </div>
                            <button type="submit" className="button primary" disabled={true}>Submit</button>
                        </form>
                    </div>
                </Section>

                <Section title="Stackla Widget">
                    <StacklaWidget {...MOCK_DATA.STACKLA_WIDGET} />
                </Section>
            </main>
        );
    }
}

export default StyleGuidePage;
