/* eslint-disable require-jsdoc */
import { dataLayerPush } from 'app/utilities/gtm';
import { ENDPOINTS } from 'config/api';
import ENQUIRY_FORM_FIELDS from 'config/enquiry-form-fields';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

const RESET_ENQUIRY_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY = 5000; // 5 seconds

export const INITIAL_STATE = {
    fields: ENQUIRY_FORM_FIELDS.reduce((acc, { name, defaultValue, isBookFunctionsField }) => {
        acc[name] = {
            value: defaultValue ? defaultValue : '',
            touched: false,
            isBookFunctionsField
        };

        return acc;
    }, {}),
    isBookFunctionsActive: false,
    submitted: false,
    submittedSuccessfully: undefined,
    venueOptions: []
};

// Enquiry Form Actions
export const SET_ENQUIRY_FORM_FIELD_VALUE = 'rfa-stadiums-website/enquiry/SET_ENQUIRY_FORM_FIELD_VALUE';
export const TOUCHED_ENQUIRY_FORM_FIELD = 'rfa-stadiums-website/enquiry/TOUCHED_ENQUIRY_FORM_FIELD';
export const SUBMIT_ENQUIRY_FORM = 'rfa-stadiums-website/enquiry/SUBMIT_ENQUIRY_FORM';
export const ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED = 'rfa-stadiums-website/enquiry/ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_ENQUIRY_FORM = 'rfa-stadiums-website/enquiry/FAILED_TO_SUBMIT_ENQUIRY_FORM';
export const RESET_ENQUIRY_FORM = 'rfa-stadiums-website/enquiry/RESET_ENQUIRY_FORM';
export const FETCH_VENUE_OPTIONS = 'rfa-conventions-website/enquiry/FETCH_VENUE_OPTIONS';
export const FETCH_VENUE_OPTIONS_SUCCESS = 'rfa-conventions-website/enquiry/FETCH_VENUE_OPTIONS_SUCCESS';

// Enquiry Form Action Creators
export const setEnquiryFormFieldValueAction = (fieldName, value) => ({
    type: SET_ENQUIRY_FORM_FIELD_VALUE,
    fieldName,
    value
});

export const touchedEnquiryFormFieldAction = (fieldName) => ({
    type: TOUCHED_ENQUIRY_FORM_FIELD,
    fieldName
});

export const submitEnquiryFormAction = (formData) => ({
    type: SUBMIT_ENQUIRY_FORM,
    formData
});

export const enquiryFormSuccessfullySubmittedAction = () => ({
    type: ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitEnquiryFormAction = () => ({
    type: FAILED_TO_SUBMIT_ENQUIRY_FORM
});

export const resetEnquiryFormAction = () => ({
    type: RESET_ENQUIRY_FORM
});

export const fetchVenueOptionsAction = (location) => ({
    type: FETCH_VENUE_OPTIONS,
    location
});

export const fetchVenueOptionsSuccessAction = (venueOptions) => ({
    type: FETCH_VENUE_OPTIONS_SUCCESS,
    venueOptions
});

// Enquiry Form Reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ENQUIRY_FORM_FIELD_VALUE:
            return setEnquiryFormFieldValue(state, action.fieldName, action.value);
        case TOUCHED_ENQUIRY_FORM_FIELD:
            return touchedEnquiryFormField(state, action.fieldName);
        case SUBMIT_ENQUIRY_FORM:
            return submitEnquiryForm(state);
        case ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED:
            return enquiryFormSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_ENQUIRY_FORM:
            return failedToSubmitEnquiryForm(state);
        case FETCH_VENUE_OPTIONS:
            return fetchVenueOptions(state, action.location);
        case FETCH_VENUE_OPTIONS_SUCCESS:
            return fetchVenueOptionsSuccess(state, action.venueOptions);
        case RESET_ENQUIRY_FORM:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Enquiry Form Reducer Helpers
function setEnquiryFormFieldValue(state, fieldName, value) {
    if (fieldName === 'subject') {
        return {
            ...state,
            fields: {
                ...state.fields,
                [fieldName]: {
                    ...state.fields[fieldName],
                    value
                },
            },
            isBookFunctionsActive: value === 'Book a function'
        };
    }

    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                value
            }
        }
    };
}

function touchedEnquiryFormField(state, fieldName) {
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                touched: true
            }
        }
    };
}

function submitEnquiryForm(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function enquiryFormSuccessfullySubmitted(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true
    };
}

function failedToSubmitEnquiryForm(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false
    };
}

function fetchVenueOptions(state) { // eslint-disable-line require-jsdoc
    return {
        ...state
    };
}

function fetchVenueOptionsSuccess(state, venueOptions) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        venueOptions
    };
}

// Epic creators helpers
function trackEnquiryFormSubmission(formData) {
    /* ↓ Tracking successful form submissions */
    /* eslint-disable camelcase */
    // eslint-disable-next-line no-undef
    dataLayerPush({
        event: 'formSubmitted', // Overrides the default 'conversionTracking' value.
        form_conversion: 'macro_conversion',
        form_type: 'lead',
        form_action: 'enquiry_form_submission',
        form_data: JSON.stringify(formData),
        form_text: formData.message,
        form_subject: formData.subject
    });
    /* eslint-enable camelcase */
    /* ↑ Tracking successful form submissions */
}

const fetchVenueOptionsSuccessActionSequence = (venueOptions) => {
    // Enquiry form 'venues' field
    const enquiryFromField = {
        fieldName: 'venues',
        value: []
    };

    return [
        setEnquiryFormFieldValueAction(enquiryFromField.fieldName, enquiryFromField.value),
        fetchVenueOptionsSuccessAction(venueOptions)
    ];
};

// Epic creators
/**
 * Creates enquiry form submission epic
 * @param {string} enquiryEndpoint - get in touch endpoint
 * @param {enquiryFormSuccessfullySubmittedAction} enquiryFormSuccessfullySubmittedAction - action creator
 * @param {failedToSubmitEnquiryFormAction} failedToSubmitEnquiryFormAction - action creator
 * @returns {array}
 */
export const createEnquiryFormSubmissionEpic = (enquiryEndpoint, enquiryFormSuccessfullySubmittedAction, failedToSubmitEnquiryFormAction) => {
    return (action$) => action$.pipe(
        ofType(SUBMIT_ENQUIRY_FORM),
        mergeMap(({ formData }) => {
            return (
                fetch(enquiryEndpoint, null, {
                    method: 'POST',
                    body: JSON.stringify(formData)
                })
                    .then(() => {
                        trackEnquiryFormSubmission(formData);

                        return enquiryFormSuccessfullySubmittedAction();
                    })
                    .catch(failedToSubmitEnquiryFormAction)
            );
        })
    );
};

export const createScheduleEnquiryFormResettingEpic = (setTimeout, resetEnquiryFormOnSuccessfullSubmissionDelay) => {
    return (action$) => action$.pipe(
        ofType(ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED),
        mergeMap(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(resetEnquiryFormAction());
                }, resetEnquiryFormOnSuccessfullSubmissionDelay);
            });
        })
    );
};

export const createFetchVenueOptionsEpic = (venuesEndpoint, fetchVenueOptionsSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_VENUE_OPTIONS),
        mergeMap(() => {
            return (
                fetch(venuesEndpoint())
                    .then((response) => {
                        const venueOptions = formatVenueOptionsResponse(response);

                        return fetchVenueOptionsSuccessActionSequence(venueOptions);
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchVenueOptionsSuccessAction();
                    })
            );
        })
    );
};

// Epics
const enquiryFormSubmissionEpic = createEnquiryFormSubmissionEpic(
    ENDPOINTS.ENQUIRY,
    enquiryFormSuccessfullySubmittedAction,
    failedToSubmitEnquiryFormAction
);

const scheduleFormResettingEpic = createScheduleEnquiryFormResettingEpic(
    setTimeout,
    RESET_ENQUIRY_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY
);

const fetchVenueOptionsEpic = createFetchVenueOptionsEpic(
    ENDPOINTS.VENUES,
    fetchVenueOptionsSuccessAction,
    formatVenueOptionsResponse
);

export const epics = combineEpics(
    enquiryFormSubmissionEpic,
    scheduleFormResettingEpic,
    fetchVenueOptionsEpic
);

/*
    Format
 */

/**
 * Formats the Venues API response into the desired dropdown list shape
 *
 * @param  {object} response      - Response from API
 * @return {array} venueOptions   - Array of the venue options
 */
export function formatVenueOptionsResponse(response) {
    const { data } = response;
    const venueOptions = [];

    // Re-format each response item
    data.forEach(({ attributes }) => {
        // Hard code to remove "go-media-stadium" since CMS still need this for couple of months
        // TODO: remove filter when cms "go-media-stadium" has been removed
        if (attributes.slug === 'go-media-stadium') {
            return;
        }
        const { name, thumbnail } = attributes;

        venueOptions.push({
            label: name,
            value: name,
            thumbnail: thumbnail
        });
    });

    return venueOptions;
}
