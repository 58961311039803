import fieldTypes from 'config/field-types';

export function createValidationDataObject(valid = true, message = undefined) { // eslint-disable-line require-jsdoc
    return {
        valid,
        message
    };
}

export const VALIDATOR_TYPES = {
    REQUIRED: 'REQUIRED',
    REQUIRED_EMAIL: 'REQUIRED_EMAIL'
};

const VALIDATORS = {
    [VALIDATOR_TYPES.REQUIRED]: (s) => {
        if (s) {
            return createValidationDataObject();
        }

        return createValidationDataObject(false, 'This is a required field');
    },
    [VALIDATOR_TYPES.REQUIRED_EMAIL]: (s) => {
        if (s) {
            // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
            if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(s)) { // eslint-disable-line
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'Not a valid email address');
        }

        return createValidationDataObject(false, 'This is a required field');
    }
};

export function validate(validatorType, value, touched) { // eslint-disable-line require-jsdoc
    if (validatorType) {
        return touched ? VALIDATORS[validatorType](value) : createValidationDataObject();
    }

    return createValidationDataObject();
}

export function formIsValid(form) { // eslint-disable-line require-jsdoc
    return Object.keys(form).every((formFieldName) => {
        const formField = form[formFieldName];
        const { fieldType, validatorType, value } = formField;
        switch (fieldType) {
            case fieldTypes.FIELD_GROUP_REPEATER:
                return value.every((fieldGroup) => formIsValid(fieldGroup));
            default:
                return validate(validatorType, value, true).valid;
        }
    });
}

export default VALIDATORS;
