// This needs to be kept in sync with the page parent options in the CMS.
export default [
    {
        slug: 'news',
        name: 'News'
    },
    {
        slug: 'our-venues',
        name: 'Our venues'
    },
    {
        slug: 'whats-on',
        name: 'What\'s on'
    },
    {
        slug: 'about-us',
        name: 'About us'
    },
    {
        slug: 'visitor-info',
        name: 'Visitor info'
    }
];
