export function selectFormFields(state) {
    return state.inviteForm.fields;
}

export function selectSubmitted(state) {
    return state.inviteForm.submitted;
}

export function selectIsBusy(state) {
    const { inviteForm } = state;

    return inviteForm.submitted && inviteForm.submittedSuccessfully === undefined;
}

export function selectSubmittedSuccessfully(state) {
    return state.inviteForm.submittedSuccessfully;
}
