import Data from 'app/components/partials/data';
import PropTypes from 'prop-types';
import React from 'react';

function Carousel({ items }) {
    return (
        <Data title="Content Block: Carousel" data={{ items }} />
    );
}

Carousel.propTypes = {
    items: PropTypes.array.isRequired
};

export default Carousel;
