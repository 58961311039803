import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import YoutubePlayer, { YOUTUBE_STATE } from './youtube-player';

const SLIDE_ANIMATION_DURATION = 600;
const SLIDE_ROTATION_DURATION = 5000;

class EventHeroCarousel extends Component {
    constructor() {
        super();

        this.state = EventHeroCarousel.createInitialState();

        this.updatePlayerState = this.updatePlayerState.bind(this);

        this.autoRotate = null;
    }

    static createInitialState() {
        return {
            activeSlide: 0,
            autoRotate: true,
            nextReady: true,
            playerState: YOUTUBE_STATE.UNSTARTED
        };
    }


    // Lifecycle
    componentDidMount() {
        if (this.props.images.length > 1) {
            this.startAutoRotate();
        }
    }

    UNSAFE_componentWillUnmount() {
        this.stopAutoRotate();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.images === nextProps.images) {
            this.stopAutoRotate();
            this.setState(EventHeroCarousel.createInitialState());
        }
    }


    // Helper
    updatePlayerState(playerState) {
        this.setState({ playerState });
    }

    isNext(index) {
        const { activeSlide, nextReady } = this.state;

        // If last image, first one becomes 'next' as well
        if (nextReady && index === 0 && activeSlide !== index) {
            return true;
        }

        return nextReady && ((activeSlide + 1 === index) || (activeSlide - 1 === index));
    }

    nextReadyAfterTime() {
        setTimeout(() => {
            this.setState({
                nextReady: true
            });
        }, SLIDE_ANIMATION_DURATION);
    }

    startAutoRotate() {
        this.autoRotate = setInterval(() => {
            const shouldRotate = this.state.autoRotate;
            const isLastSlide = (this.state.activeSlide + 1 === this.props.images.length);

            if (shouldRotate && isLastSlide) {
                // Restart carousel
                this.updateSlide(0);
            } else if (shouldRotate) {
                this.nextSlide();
            } else {
                // Stop the rotation
                this.stopAutoRotate();
            }
        }, SLIDE_ROTATION_DURATION);
    }

    stopAutoRotate() {
        clearInterval(this.autoRotate);
    }

    previousSlide() {
        const nextActiveSlide = Math.max(this.state.activeSlide - 1, 0);

        this.updateSlide(nextActiveSlide);
    }

    nextSlide() {
        const nextActiveSlide = Math.min(this.state.activeSlide + 1, this.props.images.length - 1);

        this.updateSlide(nextActiveSlide);
    }

    updateSlide(slide = 0) {
        this.setState({
            activeSlide: slide,
            nextReady: false
        }, this.nextReadyAfterTime);
    }


    // Handler
    handlePrevious() {
        this.setState({
            autoRotate: false
        }, this.previousSlide());
    }

    handleNext() {
        this.setState({
            autoRotate: false
        }, this.nextSlide());
    }


    // Render
    renderCarousel() {
        const { activeSlide } = this.state;
        const { title, images } = this.props;

        return (
            <div className="carousel">
                <div className="carousel-inner || preserve-aspect-ratio">
                    {images.map((image, index) => {
                        const itemClass = classnames('carousel-media', {
                            'is-active': (activeSlide === index),
                            'is-next': this.isNext(index)
                        });

                        return (
                            <div className={itemClass} key={`carousel-media-${index}`}>
                                <div className="carousel-image || preserve-aspect-ratio-media || image-overlay" style={{ backgroundImage: `url(${image})` }}>
                                    <img className="fixed-image" src={image} alt={title} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderVideo() {
        const { video, images } = this.props;
        const { playerState } = this.state;
        const coverImage = images[0];

        const imagesClass = classnames('event-hero-carousel-video-image || preserve-aspect-ratio-media || image-overlay', {
            'is-hidden':  playerState === YOUTUBE_STATE.PLAYING
        });

        return (
            <div className="event-hero-carousel-video">
                <YoutubePlayer
                    playerState={playerState}
                    updatePlayerState={this.updatePlayerState}
                    url={video}
                />
                <div aria-hidden={true} className={imagesClass} style={{ backgroundImage: `url(${coverImage})` }}></div>
            </div>
        );
    }

    render() {
        const { title, date, description, video, venue, buyTicketLink } = this.props;

        return (
            <div className="event-hero-carousel || constrain-width no-pad">
                {video && this.renderVideo()}
                {!video && this.renderCarousel()}
                <div className="event-hero-carousel-intro">
                    <h1 className="event-hero-carousel-heading">{title}</h1>
                    {date &&
                        <span className="event-hero-carousel-detail">
                            <Icon name="event-calendar"/>
                            {date}
                        </span>
                    }
                    {venue &&
                        <span className="event-hero-carousel-detail icon">
                            <Icon name="location"/>
                            {venue}
                        </span>
                    }
                    {description && <span className="event-hero-carousel-detail info">{description}</span>}
                    {buyTicketLink && (
                        <a className="event-hero-carousel-cta" href={buyTicketLink}>
                            <button type="button" className="button primary">Buy Tickets</button>
                        </a>
                    )}
                </div>
            </div>
        );
    }
}

EventHeroCarousel.propTypes = {
    date: PropTypes.string.isRequired,
    description: PropTypes.string,
    images: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    video: PropTypes.string,
    venue: PropTypes.string.isRequired,
    buyTicketLink: PropTypes.string
};

export default EventHeroCarousel;
