/* eslint-disable camelcase, quotes */
export default {
    TEXT_PROPS: {
        data: {
            content: '<h1>Text only</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>'
        }
    },
    TEXT_ASIDE_PROPS: {
        data: {
            content: '<h1>Text with aside</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
            aside: '<h2>Aside content</h2><ul><li>Example list item one.</li><li>Example list item two.</li><li>Example list item three.</li><li>Example list item four.</li></ul>'
        }
    },
    TEXT_QUOTE_PROPS: {
        data: {
            content: '<h1>Text with aside</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
            quote: 'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse stie consequat',
            citation: 'Example citation text'
        }
    },
    CALL_TO_ACTION_PROPS: {
        data: {
            content: '<h1>Adele</h1><p>Go Media Stadium</p><p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</p><p><a class="button large primary" href="#" target="_blank">View Event</a></p>',
            file: 'https://unsplash.it/1920/1280'
        }
    },
    FAQ_PROPS: {
        items: [
            {
                content: '<p>Lorem ipsum dolor sit amet, <strong>consectetur adipisicing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi </p>',
                heading: 'Stadiums FAQs',
                title: 'How can I find a stadium?'
            },
            {
                content: '<p>Lorem ipsum dolor sit amet, <i>consectetur adipisicing elit</i>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi </p>',
                heading: 'Stadiums FAQs',
                title: 'How can I find a room in a stadium?'
            },
            {
                content: '<p>Underneath the stadium itself.</p>',
                heading: 'Stadiums FAQs',
                title: 'Where can I get food and beverages?'
            }
        ]
    },
    CONTENT_SET_PROPS: {
        title: "What's on",
        items: [
            { data: { name: 'Ed Sheeran', short_description: 'QBE Stadium', thumbnail: 'https://unsplash.it/640/960' } },
            { data: { name: 'Wellington Phoenix FC v Newcastle Jets FC', short_description: 'QBE Stadium', thumbnail: 'https://unsplash.it/640/961' } },
            { data: { name: 'Paul McCartney', short_description: 'Mt. Smart Stadium', thumbnail: 'https://unsplash.it/640/962' } },
            { data: { name: 'Metallica', short_description: 'Mt. Smart Stadium', thumbnail: 'https://unsplash.it/640/963' } }
        ]
    },
    VIDEO_PROPS: {
        data: {
            video_url: 'https://youtu.be/2GcTNiq0uFM',
            title: 'Video Section Title',
            credit: 'Credit '
        }
    },
    GALLERY_PROPS: {
        items: [
            {
                file: 'https://unsplash.it/1920/1280',
                creditline: '<p>A very short caption (Small)</p>'
            },
            {
                file: 'https://unsplash.it/1920/1280',
                creditline: '<p>A very simple caption with a little longer title. (Medium)</p>'
            },
            {
                file: 'https://unsplash.it/1920/1281',
                creditline: '<p>A very simple caption with a little longer title for the large class application. (Large)</p>'
            },
            {
                file: 'https://unsplash.it/1920/1282',
                creditline: '<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. (X Large)</p>'
            },
            {
                file: 'https://unsplash.it/1920/1283',
                creditline: '<p>“That’s Adele, putting on a big night out but making it seem like a deeply personal moment… Truly, a diva for the people”<br>- The Times, UK</p>'
            },
            {
                file: 'https://unsplash.it/1920/1284',
                creditline: '<p><strong>Example Slide 2</strong></p><p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>\r\n'
            },
            {
                file: 'https://unsplash.it/1920/1285',
                creditline: '<p><strong>Example Slide 3</strong></p><p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>\r\n'
            }
        ]
    },
    HERO_CAROUSEL_PROPS: {
        title: 'Hero Carousel',
        introduction: 'Your page description',
        images: [
            'https://unsplash.it/1920/1283',
            'https://unsplash.it/1920/1284',
            'https://unsplash.it/1920/1285',
        ]
    },
    STACKLA_WIDGET: {
        hash: '5a7a183bd5301',
        id: '36126',
        subtitle: 'Tag your photos and posts with #FooFighters to display your photos here.',
        title: '#FooFighters',
        type: 'Grid'
    }
};
