import env from './env';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

const APP_ID = 'stadiums';

const LIMIT_PER_PAGE = 1000;

export const ENDPOINTS = {
    ENQUIRY: `${API_BASE_URL}/stadiums/get-in-touch`,
    EVENT_REMINDER_ACTION: `${API_BASE_URL}/stadiums/event-reminder/action`,
    EVENTS: (slug) => `${API_BASE_URL}/${APP_ID}/events?slug=${encodeURIComponent(slug)}`,
    EVENTS_ALL: `${API_BASE_URL}/${APP_ID}/events?limit=${LIMIT_PER_PAGE}`,
    INVITE: `${API_BASE_URL}/stadiums/event-reminder/invite`,
    NEWS: (slug) => `${API_BASE_URL}/${APP_ID}/news?slug=${encodeURIComponent(slug)}`,
    NEWS_ALL: `${API_BASE_URL}/${APP_ID}/news?limit=${LIMIT_PER_PAGE}`,
    NOTIFICATIONS: `${API_BASE_URL}/${APP_ID}/notifications`,
    PAGES: (slug, parent = '') => `${API_BASE_URL}/${APP_ID}/pages?slug=${encodeURIComponent(slug)}&parent=${encodeURIComponent(parent)}`,
    PAGES_ALL: `${API_BASE_URL}/${APP_ID}/pages?limit=${LIMIT_PER_PAGE}`,
    PERFORMANCES: (performanceId) => `${API_BASE_URL}/${APP_ID}/performances/${encodeURIComponent(performanceId)}`,
    TOKEN: `${API_BASE_URL}/oauth/token`,
    VENUES: (slug) => {
        if (slug) {
            return `${API_BASE_URL}/${APP_ID}/venues?slug=${encodeURIComponent(slug)}`;
        }

        return `${API_BASE_URL}/${APP_ID}/venues`;
    },
    SEARCH: `${API_BASE_URL}/${APP_ID}/search`,
    VENUES_ALL: `${API_BASE_URL}/${APP_ID}/venues?limit=${LIMIT_PER_PAGE}`,
    SITEMAP: `${API_BASE_URL}/${APP_ID}/sitemap`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

export const TIMEOUT = 0;
