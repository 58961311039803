import parking from 'static/images/parking.png';

export const MT_SMART_STADIUM_CAR_PARKS = {
    PARKING_1: {
        name: 'parking-1',
        lat: -36.918121,
        lng: 174.810576,
        icon: parking
    },
    PARKING_2: {
        name: 'parking-2',
        lat: -36.916686,
        lng: 174.812615,
        icon: parking
    },
    PARKING_3: {
        name: 'parking-3',
        lat: -36.916274,
        lng: 174.813744,
        icon: parking
    },
    PARKING_4: {
        name: 'parking-4',
        lat: -36.916827,
        lng: 174.816567,
        icon: parking
    },
};
