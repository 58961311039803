import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import fieldTypes from 'config/field-types';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import INVITE_FORM_FIELDS, { createInviteeFieldGroup } from 'config/invite-form-fields';

const RESET_INVITE_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY = 5000; // 5 seconds

export const INITIAL_STATE = {
    fields: Object.keys(INVITE_FORM_FIELDS).reduce((acc, formFieldName) => {
        const formField = INVITE_FORM_FIELDS[formFieldName];
        switch (formField.fieldType) {
            case fieldTypes.FIELD_GROUP_REPEATER:
                acc[formFieldName] = {
                    ...formField,
                    value: (formField.defaultValue ? formField.defaultValue : []).map((fieldGroup) => {
                        return Object.keys(fieldGroup).reduce((acc, fieldGroupItemName) => {
                            const fieldGroupItem = fieldGroup[fieldGroupItemName];
                            acc[fieldGroupItemName] = {
                                ...fieldGroupItem,
                                value: fieldGroupItem.defaultValue ? fieldGroupItem.defaultValue : '',
                                touched: false
                            };

                            return acc;
                        }, {});
                    })
                };
                break;
            default:
                acc[formFieldName] = {
                    ...formField,
                    value: formField.defaultValue ? formField.defaultValue : '',
                    touched: false
                };
                break;
        }

        return acc;
    }, {}),
    submitted: false,
    submittedSuccessfully: undefined
};


// Invite Form Actions
export const SET_INVITE_FORM_FIELDS = 'rfa-stadiums-website/invite-form/SET_INVITE_FORM_FIELDS';
export const ADD_INVITEE = 'rfa-stadiums-website/invite-form/ADD_INVITEE';
export const SUBMIT_INVITE_FORM = 'rfa-stadiums-website/invite-form/SUBMIT_INVITE_FORM';
export const INVITE_FORM_SUCCESSFULLY_SUBMITTED = 'rfa-stadiums-website/invite-form/INVITE_FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_INVITE_FORM = 'rfa-stadiums-website/invite-form/FAILED_TO_SUBMIT_INVITE_FORM';
export const RESET_INVITE_FORM = 'rfa-stadiums-website/invite-form/RESET_INVITE_FORM';

// Invite Form Action Creators
export const setInviteFormFieldsAction = (fields) => ({
    type: SET_INVITE_FORM_FIELDS,
    fields
});

export const addInviteeAction = () => ({
    type: ADD_INVITEE
});

export const submitInviteFormAction = (formData, captchaToken) => ({
    type: SUBMIT_INVITE_FORM,
    formData,
    captchaToken
});

export const inviteFormSuccessfullySubmittedAction = () => ({
    type: INVITE_FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitInviteFormAction = () => ({
    type: FAILED_TO_SUBMIT_INVITE_FORM
});

export const resetInviteFormAction = () => ({
    type: RESET_INVITE_FORM
});


// Invite Form Reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_INVITE_FORM_FIELDS:
            return setInviteFormFields(state, action.fields);
        case ADD_INVITEE:
            return addInvitee(state);
        case SUBMIT_INVITE_FORM:
            return submitInviteForm(state);
        case INVITE_FORM_SUCCESSFULLY_SUBMITTED:
            return inviteFormSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_INVITE_FORM:
            return failedToSubmitInviteForm(state);
        case RESET_INVITE_FORM:
            return INITIAL_STATE;
        default:
            return state;
    }
};


// Invite Form Reducer Helpers
function setInviteFormFields(state, fields) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        fields
    };
}

function addInvitee(state) { // eslint-disable-line require-jsdoc
    const newInviteeFieldGroup = createInviteeFieldGroup();

    const g = Object.keys(newInviteeFieldGroup).reduce((acc, formFieldName) => {
        const formField = newInviteeFieldGroup[formFieldName];
        acc[formFieldName] = {
            ...formField,
            value: formField.defaultValue ? formField.defaultValue : '',
            touched: false
        };

        return acc;
    }, {});

    return {
        ...state,
        fields: {
            ...state.fields,
            invitees: {
                ...state.fields.invitees,
                value: [...state.fields.invitees.value, g]
            }
        }
    };
}

function submitInviteForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function inviteFormSuccessfullySubmitted(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true
    };
}

function failedToSubmitInviteForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false
    };
}


// Epic creators
export const createInviteFormSubmissionEpic = (inviteEndpoint, inviteFormSuccessfullySubmittedAction, failedToSubmitInviteFormAction) => { // eslint-disable-line require-jsdoc
    return (action$) => action$.pipe(
        ofType(SUBMIT_INVITE_FORM),
        mergeMap(({ formData, captchaToken }) => {
            /**
             Uncomment the following return statement to mock successful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(inviteFormSuccessfullySubmittedAction());
            //     }, 3000);
            // });

            /**
             Uncomment the following return statement to mock unsuccessful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(failedToSubmitInviteFormAction());
            //     }, 3000);
            // });

            return (
                fetch(inviteEndpoint, null, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...formData,
                        captchaToken,
                    })
                })
                    .then(() => {
                        return inviteFormSuccessfullySubmittedAction();
                    })
                    .catch(failedToSubmitInviteFormAction)
            );
        })
    );
};

export const createScheduleInviteFormResettingEpic = (setTimeout, resetInviteFormOnSuccessfullSubmissionDelay) => {
    return (action$) => action$.pipe(
        ofType(INVITE_FORM_SUCCESSFULLY_SUBMITTED),
        mergeMap(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(resetInviteFormAction());
                }, resetInviteFormOnSuccessfullSubmissionDelay);
            });
        })
    );
};


// Epics
const inviteFormSubmissionEpic = createInviteFormSubmissionEpic(
    ENDPOINTS.INVITE,
    inviteFormSuccessfullySubmittedAction,
    failedToSubmitInviteFormAction
);

const scheduleFormResettingEpic = createScheduleInviteFormResettingEpic(
    setTimeout,
    RESET_INVITE_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY
);

export const epics = combineEpics(
    inviteFormSubmissionEpic,
    scheduleFormResettingEpic
);


// Helpers
export function generateInviteFormData(fields, pathname, eventName) { // eslint-disable-line require-jsdoc
    return {
        performance: {
            id: pathname.split('/')[2],
            name: eventName
        },
        inviter: {
            name: fields.name.value
        },
        invitees: fields.invitees.value.map((fieldGroupItem) => {
            return {
                name: fieldGroupItem.name.value,
                email: fieldGroupItem.email.value
            };
        })
    };
}
