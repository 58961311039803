import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function Section({ children, description, title, type }) {
    return (
        <section
            className={classNames('style-guide-section', {
                'constrain-width': type === 'constrain'
            })}
            id={`section-${encodeURI(title.toLowerCase())}`}>
            <header
                className={classNames('style-guide-section-header', {
                    'constrain-width': type !== 'constrain'
                })}>
                <h2 className="title">{title}</h2>
                <hr />
                <p>{description}</p>
            </header>
            {children}
        </section>
    );
}

Section.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ]),
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string
};

export default Section;
