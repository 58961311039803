import PropTypes from 'prop-types';
import {
    DirectionsRenderer,
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps';
import React, { Component } from 'react';

const defaultZoom = 16;

class Map extends Component {
    render() {
        const { lat, lng, zoom = defaultZoom, options = null, showMarker, markerIcon, directions, groundOverlay, children } = this.props;

        const centerCoordinates = this.props.markerCoordinates ? { ...this.props.markerCoordinates } : { lat, lng };

        return (
            <GoogleMap
                zoom={zoom}
                defaultCenter={{ lat, lng }}
                options={options}
                center={centerCoordinates}
            >
                {directions && <DirectionsRenderer directions={directions} options={{ polylineOptions: {
                    strokeColor: '#00B3FD',
                    strokeWeight: 5,
                    strokeOpacity: 1,
                } }} />}
                {showMarker &&
                    <Marker
                        icon={{ url: markerIcon }}
                        position={{ ...this.props.markerCoordinates }}
                    />
                }
                {groundOverlay}
                {children}
            </GoogleMap>
        );
    }
}

Map.propTypes = {
    children: PropTypes.any,
    directions: PropTypes.object,
    groundOverlay: PropTypes.any,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    markerCoordinates: PropTypes.object,
    markerIcon: PropTypes.string,
    options: PropTypes.object,
    showMarker: PropTypes.bool,
    zoom: PropTypes.number
};

export default withScriptjs(withGoogleMap(Map));
