/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import InviteForm from './invite-form';
import {
    addInviteeAction,
    setInviteFormFieldsAction,
    submitInviteFormAction
} from 'app/ducks/invite-form';
import {
    selectFormFields,
    selectIsBusy,
    selectSubmitted,
    selectSubmittedSuccessfully
} from 'app/selectors/invite-form';

function mapStateToProps(state) {
    return {
        formFields: selectFormFields(state),
        submitted: selectSubmitted(state),
        isBusy: selectIsBusy(state),
        submittedSuccessfully: selectSubmittedSuccessfully(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addInvitee: () => dispatch(addInviteeAction()),
        setFormFields: (formFields) => dispatch(setInviteFormFieldsAction(formFields)),
        submitForm: (formData, captchaToken) => dispatch(submitInviteFormAction(formData, captchaToken))
    };
}

const VisibleEnquiryForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteForm);

export default VisibleEnquiryForm;
