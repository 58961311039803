import defaultBannerImage from 'static/images/default-page-banner.jpg';
import Error from './error/error-page';
import { getOpenGraphSlug } from 'app/utilities/url';
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import NotFound from './error/not-found-page';
import printBreadcrumbList from 'app/utilities/print-breadcrumb-list';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import Template from 'app/components/templates/index';
import templateType from 'app/utilities/template-type';

const ContentPage = ({ page }) => {
    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR) return <Error />;
    if (page.error && page.error.status === RESPONSE.NOT_FOUND || !page.attributes) return <NotFound />;

    const {
        is_published: isPublished,
        name: title,
        main_images: mainImages,
        seo_description: seoDescription,
        seo_keywords: seoKeywords,
        slug,
        parent,
        thumbnail
    } = page.attributes;

    const { type } = page;
    const openGraphSlug = getOpenGraphSlug(slug, parent, page.id, type);

    let image = mainImages && mainImages[0] || thumbnail;

    if (!image) {
        image = `https://www.aucklandstadiums.co.nz${defaultBannerImage}`;
    }

    const isNoIndex = !isAppProd || !isPublished;

    return (
        <main className="page" role="main">
            <Helmet>
                {isNoIndex && <meta name="robots" content="noindex, nofollow" />}

                <title>{title}</title>
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />

                {/* Schema.org for Google+ */}
                <meta itemProp="name" content={title} />
                <meta itemProp="description" content={seoDescription} />
                <meta itemProp="image" content={image} />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={seoDescription} />
                <meta name="twitter:image" content={image} />

                {/* Open Graph */}
                <meta property="og:locale" content="en_NZ" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={seoDescription} />
                <meta property="og:url" content={`https://www.aucklandstadiums.co.nz/${openGraphSlug}`} />
                <meta property="og:image" content={image} />
            </Helmet>
            <Template
                type={templateType(slug, type)}
                data={page.attributes}
            />
            {printBreadcrumbList(page)}
        </main>
    );
};

ContentPage.propTypes = {
    page: PropTypes.object.isRequired
};

export default ContentPage;
