export const formatSearchResults = (apiData) => {
    let count = 0,
        links = '';
    const totalResults = [];

    apiData.forEach((item, index) => {
        totalResults.push(...item.data);
        if (index + 1 === apiData.length) {
            links = item.links;
            count = item.meta.result_count;
        }
    });


    return {
        count,
        links,
        results: totalResults
    };
};
