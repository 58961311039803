import PropTypes from 'prop-types';
import React from 'react';

function Anchor({ fragment }) { // eslint-disable-line require-jsdoc
    return (
        <div id={fragment} />
    );
}

Anchor.propTypes = {
    fragment: PropTypes.string.isRequired
};

export default Anchor;
