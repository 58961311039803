import { clientEnv } from 'config/env';
import { createValidationDataObject } from 'app/utilities/form-validation';
import ENQUIRY_FORM_FIELDS from 'config/enquiry-form-fields';
import fieldTypes from 'config/field-types';
import Loader from './loader';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';

const EnquiryForm = ({
    getFieldTouched,
    getFieldValue,
    setFieldValue,
    submitForm,
    submitted,
    touchedField,
    isBookFunctionsActive,
    isBusy,
    submittedSuccessfully,
    venueOptions,
    fetchVenueOptions
}) => {
    const location = useLocation();

    useEffect(() => {
        fetchVenueOptions();
    }, []);
console.log(venueOptions);
    const submitHandler = (event) => {
        event.preventDefault();

        if (!submitted) {
            const formData = ENQUIRY_FORM_FIELDS.reduce((acc, field) => {
                if (!isBookFunctionsActive && field.isBookFunctionsField) {
                    return acc;
                }
                acc[field.name] = getFieldValue(field.name);

                return acc;
            }, {});

            formData.origin = location.pathname;

            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
                // eslint-disable-next-line no-undef
                grecaptcha.execute(clientEnv.RECAPTCHA_V3_KEY, { action: 'submit' }).then(function (token) {
                    // Pass recaptcha verify token for backend to validate
                    formData.recaptchaToken = token;
                    submitForm(formData);
                });
            });
        }
    };

    const renderField = ({ name, label, placeholder, type, validator, fieldType, required, options, defaultValue, isBookFunctionsField }) => {
        const value = getFieldValue(name);
        const touched = getFieldTouched(name);
        const validationData = touched ? validator(value) : createValidationDataObject();

        let input = null,
            selectOptions = options;

        if (!isBookFunctionsActive && isBookFunctionsField) {
            return;
        }

        if (name === 'interested-venues') {
            selectOptions = venueOptions;
        }

        switch (fieldType) {
            case fieldTypes.INPUT:
                input =
                    <input
                        className={`form-field || input ${validationData.valid ? '' : 'is-invalid'}`}
                        id={name}
                        type={type}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onChange={(event) => {
                            setFieldValue(name, event.target.value);
                            touchedField(name);
                        }}
                        onBlur={() => touchedField(name)}
                    />;
                break;
            case fieldTypes.MULTISELECT:
                input =
                    <Select
                        id={name}
                        className={`select ${validationData.valid ? '' : 'is-invalid'}`}
                        classNamePrefix="select"
                        placeholder={placeholder}
                        options={selectOptions}
                        defaultValue={defaultValue}
                        required={required}
                        value={selectOptions.filter((item) => value.includes(item.value))}
                        onChange={(selectedOptionArray) => {
                            const filterSelectedValue = selectedOptionArray.map((item) => item.value);

                            setFieldValue(name, filterSelectedValue);
                            touchedField(name);
                        }}
                        onBlur={() => touchedField(name)}
                        isMulti={true}
                    />;
                break;
            case fieldTypes.SELECT:
                input =
                    <Select
                        id={name}
                        className={`select ${validationData.valid ? '' : 'is-invalid'}`}
                        classNamePrefix="select"
                        placeholder={placeholder}
                        options={options}
                        defaultValue={defaultValue}
                        required={required}
                        value={options.find((item) => item.value === value)}
                        onChange={(selectedOption) => {
                            setFieldValue(name, selectedOption.value);
                            touchedField(name);
                        }}
                        onBlur={() => touchedField(name)}
                    />;
                break;
            case fieldTypes.TEXTAREA:
                input =
                    <textarea
                        className={`form-field || input ${validationData.valid ? '' : 'is-invalid'} || text-area`}
                        id={name}
                        type={type}
                        required={required}
                        value={value}
                        onChange={(event) => {
                            setFieldValue(name, event.target.value);
                            touchedField(name);
                        }}
                        onBlur={() => touchedField(name)}
                    />;
                break;
            default:
                break;
        }

        return (
            <div className={`form-fieldset ${required ? 'is-required' : ''}`} key={name}>
                <label className="form-label" htmlFor={name}>{label}</label>
                {!validationData.valid && (
                    <span className="form-validation">This is a required field</span>
                )}
                {input}
            </div>
        );
    };

    const renderSubmitButton = () => {
        const invalid = ENQUIRY_FORM_FIELDS.some(({ name, validator, isBookFunctionsField }) => {
            if (!isBookFunctionsActive && isBookFunctionsField) {
                return false;
            }

            return !validator(getFieldValue(name)).valid;
        });

        return (
            <>
                <p><em>We manage your personal information in accordance with our <Link to="/terms-and-privacy-policy">Privacy Policy</Link>
                </em></p>
                <div key="form-actions" className="form-actions">
                    <button
                        key="button"
                        className="button primary large"
                        type="submit"
                        disabled={invalid}>
                        Submit
                    </button>
                </div>
            </>
        );
    };

    const renderSubmitSection = () => {
        if (isBusy) {
            return [
                <p key="validation" className="form-result is-inactive"></p>,
                <div key="form-actions" className="form-actions">
                    <button className="button primary large"><Loader type="small" /></button>
                </div>
            ];
        }

        if (submitted) {
            if (submittedSuccessfully) {
                return [
                    <p key="validation" className="form-result is-active">Thanks for getting in touch! A member of our team will get back to you within 2 business days.</p>,
                    <div key="form-actions" className="form-actions">
                        <button key="button" className="button primary large">Sent</button>
                    </div>
                ];
            }

            return [
                <p key="validation" className="form-result is-active">Sorry, we didn&apos;t get your message. Try again later.</p>,
                renderSubmitButton()
            ];
        }

        return [
            <p key="validation" className="form-result is-inactive"></p>,
            renderSubmitButton()
        ];
    };

    return (
        <form
            className="form"
            noValidate={true}
            action="#"
            onSubmit={submitHandler}>
            {ENQUIRY_FORM_FIELDS.map(renderField)}
            {renderSubmitSection()}
        </form>
    );
};

EnquiryForm.propTypes = {
    getFieldTouched: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    isBookFunctionsActive: PropTypes.bool.isRequired,
    isBusy: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool,
    touchedField: PropTypes.func.isRequired,
    venueOptions: PropTypes.array.isRequired,
    fetchVenueOptions: PropTypes.func.isRequired
};

export default EnquiryForm;
