/* eslint-disable require-jsdoc */
import canUseDom from './utilities/dom';
import { configureStore } from './store';
import { HistoryRouter } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';
import React from 'react';
import Routes from './router';
import { StaticRouter } from 'react-router-dom/server';
import { createBrowserHistory, createMemoryHistory } from 'history';

// Import styles
import './sass/index';

export default function getApp(context, url, initialState, extraState) {
    const history = canUseDom() ?
        createBrowserHistory() :
        createMemoryHistory({ initialEntries: [url] });

    const { reduxStore, reduxHistory } = configureStore(initialState, history);

    /**
     * Re-build the correct state for SSR
     */
    if (context === 'server' && extraState) {
        // TODO: Dispatch different action to re-build the correctly state here ...
    }

    const Router = {
        server: StaticRouter,
        client: HistoryRouter
    }[context];

    const routerProps = {
        server: { context: {}, location: url },
        client: { history: reduxHistory }
    }[context];

    const app = (
        <Provider store={reduxStore}>
            <Router {...routerProps}>
                <Routes />
            </Router>
        </Provider>
    );

    return {
        app: app,
        latestState: reduxStore.getState()
    };
}
