/**
 * Format the WYSIWYG content
 *
 * @param  {string} content WYSIWYG content
 * @param  {string} type component type (currently only used for call-to-action component)
 * @param  {string} buttonClass custom button class
 *
 * @return {string} formatted content
 */
export default function(content, type = null, buttonClass = 'button primary') {
    // Change the button class
    const formattedContent = content.replace(
        /class="small primary button"/g,
        `class="${buttonClass}"`
    ).replace(
        /<h1>/g,
        getHeadingElement(type)
    ).replace(
        /<(\/)h1>/g,
        '</h2>'
    );

    return formattedContent;
}

/**
 * Currently it returns the H2 with specific heading styles for the call-to-action component.
 * @param {string} type - The component type
 */
function getHeadingElement(type) {
    return type === 'call-to-action' ? '<h2 class="heading-2">' : '<h2 class="heading-1">';
}
