import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { useRef, useState } from 'react';

const HUNDRED_PERCENT = 100;

const Gallery = ({ items }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const sliderRef = useRef(null);

    // Handler
    const handleNext = () => {
        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'gallery_click',
            text: items[activeItemIndex].file
        });
        /* ↑ Tracking gallery scroll events */

        sliderRef.current.slickNext();
    };

    const handlePrevious = () => {
        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'gallery_click',
            text: items[activeItemIndex].file
        });
        /* ↑ Tracking gallery scroll events */

        sliderRef.current.slickPrev();
    };

    const activeCaptionLength = items[activeItemIndex].creditline.toString().length || 0;

    /* eslint-disable no-magic-numbers */
    const captionCarouselClass = classnames('caption-carousel || rte-content light || constrain-width', {
        'height-small'  : activeCaptionLength > 0 && activeCaptionLength <= 75,
        'height-medium' : activeCaptionLength > 75 && activeCaptionLength <= 120,
        'height-large'  : activeCaptionLength > 120 && activeCaptionLength <= 200,
        'height-x-large': activeCaptionLength > 200
    });

    const settings = {
        accessibility: false, // We are using native keyboard navigation instead of arrows
        adaptiveHeight: false,
        dots: false,
        draggable: true,
        infinite: true,
        pauseOnHover: true,
        speed: 600, // Set in CSS
        swipe: true,
        usecSS: true,
        arrows: false,
        beforeChange: (oldIndex, newIndex) => setActiveItemIndex(newIndex),
    };

    return (
        <section className="gallery || constrain-width x-large no-pad">
            <Slider
                className="image-carousel"
                ref={sliderRef}
                {...settings}
            >
                {items.map(({ file, link, title }, index) => {
                    return (
                        <div key={index}>
                            <a className="image-carousel-item || image-overlay" href={link} style={{ backgroundImage: `url(${file})` }} title={title}>
                                <span className="screen-reader-only-text">{`Carousel slide ${index + 1} image`}</span>
                            </a>
                        </div>
                    );
                })}
            </Slider>
            <div className={captionCarouselClass}>
                {items.map(({ creditline }, index) => {
                    return (
                        <div
                            key={index}
                            className={classnames('caption-carousel-item || rich-editor-content', { 'is-active': activeItemIndex === index })}
                            dangerouslySetInnerHTML={{ __html: creditline }}
                        />
                    );
                })}
                {items.length > 1 &&
                    <button className="caption-carousel-change-slide previous" onClick={handlePrevious}>
                        <Icon name="arrow-down" title="Previous" />
                    </button>
                }
                {items.length > 1 &&
                    <button className="caption-carousel-change-slide next" onClick={handleNext}>
                        <Icon name="arrow-down" title="Next" />
                    </button>
                }
                <div className="caption-carousel-line">
                    <div
                        className="caption-carousel-line-slider"
                        style={{
                            width: `${HUNDRED_PERCENT / items.length}%`,
                            left: `${HUNDRED_PERCENT * activeItemIndex / items.length}%`
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

Gallery.propTypes = {
    items: PropTypes.array.isRequired
};

export default Gallery;
