import { connect } from 'react-redux';
import { passEventReminderActionToTheApiAction } from 'app/ducks/event-reminder';
import Performance from './performance';

function mapStateToProps() { // eslint-disable-line require-jsdoc
    return {};
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        passEventReminderActionToTheApi: (token) => dispatch(passEventReminderActionToTheApiAction(token))
    };
}

const VisiblePerformance = connect(
    mapStateToProps,
    mapDispatchToProps
)(Performance);

export default VisiblePerformance;
