import Banner from 'app/components/partials/banner';
import Helmet from 'react-helmet';
import Icon from 'app/components/partials/icon';
import { optionGroupsConfig } from 'config/newsletter';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const NewsletterPreferencesPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [optionGroups, setOptionGroups] = useState(
        optionGroupsConfig.map(({ options }) => options.map(() => false)) // Nothing is selected by default
    );

    const [searchParams] = useSearchParams();


    const setOptionSelected = (optionGroupIndex, optionIndex, optionSelected) => {
        setOptionGroups(optionGroups.map((prevOptionGroup, prevOptionGroupIndex) => {
            if (prevOptionGroupIndex === optionGroupIndex) {
                return prevOptionGroup.map((prevOptionSelected, prevOptionIndex) => {
                    if (prevOptionIndex === optionIndex) {
                        return optionSelected;
                    }

                    return prevOptionSelected;
                });
            }

            return prevOptionGroup;
        }));
    };

    useEffect(() => {
        const emailQuery = searchParams.get('email') || '';

        if (emailQuery) setEmail(emailQuery);
    }, []);

    const nothingIsSelected = optionGroups.every((optionGroup) => optionGroup.every((optionSelected) => !optionSelected));

    return (
        <main className="page" role="main">
            <Helmet>
                <title>Keep up to date with Auckland Stadiums</title>
            </Helmet>
            <div className="constrain-width medium">
                <Banner
                    compact={true}
                    light={true}
                    mainImages={[]}
                    title="Keep up to date with Auckland Stadiums"
                />
            </div>
            <div className="constrain-width small">
                <form
                    method="post"
                    action="https://www.pages08.net/archtics-internationalcanada/AucklandStadiums-SignupPage/PreferenceCenter"
                >
                    <div className="section">
                        <div className="form-fieldset is-required">
                            <label className="form-label" htmlFor="email">Email</label>
                            <input
                                className="form-field || input"
                                id="email"
                                type="email"
                                name="Email"
                                required
                                onChange={(event) => setEmail(event.target.value)}
                                placeholder="Email"
                                value={email}
                            />
                        </div>

                        <div className="form-fieldset is-required">
                            <label className="form-label" htmlFor="firstName">First Name</label>
                            <input
                                className="form-field || input"
                                id="firstName"
                                type="text"
                                name="Name_First"
                                required
                                onChange={(event) => setFirstName(event.target.value)}
                                placeholder="First Name"
                                value={firstName}
                            />
                        </div>

                        <div className="form-fieldset is-required">
                            <label className="form-label" htmlFor="lastName">Last Name</label>
                            <input
                                className="form-field || input"
                                id="lastName"
                                type="text"
                                name="Name_Last"
                                required
                                onChange={(event) => setLastName(event.target.value)}
                                placeholder="Last Name"
                                value={lastName}
                            />
                        </div>
                    </div>
                    <div className="section">
                        {nothingIsSelected && (
                            <label className="faux-required-input-field">
                                Subscription preferences:
                                <input
                                    className="faux-required-input"
                                    required
                                    type="text"
                                    value=""
                                />
                            </label>
                        )}
                        {optionGroupsConfig.map(({ heading, description, options }, optionGroupIndex) => (
                            <div className="form-fieldset" key={optionGroupIndex}>
                                {heading && <h2>{heading}</h2>}
                                {description && <p>{description}</p>}
                                {options.map(({ value, label }, optionIndex) => {
                                    const isChecked = optionGroups[optionGroupIndex][optionIndex];
                                    const setChecked = (checked) => setOptionSelected(optionGroupIndex, optionIndex, checked); // eslint-disable-line func-style

                                    return (
                                        <label
                                            key={optionIndex}
                                            className="checkbox-form-field"
                                        >
                                            <input
                                                className="checkbox"
                                                checked={isChecked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="SUBSCRIPTION_GROUP"
                                                type="checkbox"
                                                value={value}
                                            />
                                            <Icon
                                                className="checkbox-icon"
                                                type="icon"
                                                name={isChecked ? 'checkbox-checked' : 'checkbox-unchecked'}
                                            />
                                            <span className="checkbox-label">
                                                {label}
                                            </span>
                                        </label>
                                    );
                                })}
                            </div>
                        ))}
                    </div>

                    <input type="hidden" name="Source_Name" value="Web Form" />
                    <input type="hidden" name="client_id" value="2020515" />
                    <input type="hidden" name="formSourceName" value="StandardForm" />
                    <input type="hidden" name="sp_exp" value="yes" />

                    <div className="section is-last-on-page">
                        <p><em>We manage subscriber name and email details in accordance with our <Link to="/terms-and-privacy-policy">Privacy Policy</Link>
                        </em></p>
                        <button
                            className="button primary"
                            type="submit"
                        >
                            Sign up
                        </button>
                    </div>

                </form>
            </div>
        </main>
    );
};


export default NewsletterPreferencesPage;
