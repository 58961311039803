import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.state = Faq.createInitialState();
    }

    static createInitialState() {
        return {
            itemsOpen: null
        };
    }


    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setState(Faq.createInitialState());
        }
    }


    // Handler
    handleToggle(index) {
        const { itemsOpen } = this.state;
        const { items } = this.props;
        let nextItem = null;

        if (itemsOpen !== index) {
            nextItem = index;

            /* ↓ Tracking FAQ question expansion events */
            dataLayerPush({
                id: 'micro_conversion',
                type: 'engaging_content',
                action: 'faq_click',
                text: items[nextItem].title
            });
            /* ↑ Tracking FAQ question expansion events */
        }

        this.setState({
            itemsOpen: nextItem
        });
    }


    // Render
    render() {
        const { items } = this.props;
        const { itemsOpen } = this.state;

        // TODO: Heading should not be nested withing each item?
        const heading = items[0].heading === '' ? null : items[0].heading;
        const accordionItemClass = 'accordion-item';

        return (
            <section className="accordion">
                <div className="accordion-inner || constrain-width">
                    <h3 className="heading-3">{heading}</h3>
                    {items.map(({ content, title }, index) => {
                        const itemClass = classnames(accordionItemClass, {
                            'is-active': itemsOpen === index
                        });

                        return (
                            <div className={itemClass} key={index}>
                                <button className={`${accordionItemClass}-header`} onClick={() => this.handleToggle(index)}>
                                    <h4 className={`${accordionItemClass}-title`}>{title}</h4>
                                    <div className="icon-button small">
                                        <Icon name="arrow-down" />
                                    </div>
                                </button>
                                <div className={`${accordionItemClass}-content`}>
                                    <div className="constrain-content || rte-content || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content) }}></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        );
    }
}

Faq.propTypes = {
    items: PropTypes.array.isRequired
};

export default Faq;
