import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

const CallToAction = (props) => {
    const { content, file } = props.data;

    return (
        <section className="call-to-action || constrain-width x-large no-pad">
            <div className="call-to-action-inner || image-overlay" style={{ backgroundImage: `url(${file})` }}>
                <div className="call-to-action-media">
                    <img className="image" src={file} alt={file} />
                </div>
                <div className="call-to-action-main || constrain-width">
                    <div
                        className="call-to-action-content || rte-content light || rich-editor-content"
                        dangerouslySetInnerHTML={{ __html: formatContent(content, 'call-to-action') }}>
                    </div>
                </div>
            </div>
        </section>
    );
};

CallToAction.propTypes = {
    data: PropTypes.object.isRequired
};

export default CallToAction;
