import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { epics as eventReminderEpics } from './event-reminder';
import status from './status';
import strip from './strip';
import enquiryForm, { epics as enquiryFormEpics } from './enquiry-form';
import inviteForm, { epics as inviteFormEpics } from './invite-form';
import notifications, { epics as notificationsEpics } from './notifications';
import pages, { epics as pageEpics } from './page';

export const epics = combineEpics(
    enquiryFormEpics,
    eventReminderEpics,
    inviteFormEpics,
    notificationsEpics,
    pageEpics
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    enquiryForm,
    inviteForm,
    notifications,
    pages,
    status,
    strip
});

export default createRootReducer;

