/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import EnquiryForm from './enquiry-form';
import {
    fetchVenueOptionsAction,
    setEnquiryFormFieldValueAction,
    submitEnquiryFormAction,
    touchedEnquiryFormFieldAction
} from 'app/ducks/enquiry-form';
import {
    selectFieldTouched,
    selectFieldValue,
    selectIsBookFunctionsActive,
    selectIsBusy,
    selectSubmitted,
    selectSubmittedSuccessfully,
    selectVenueOptions
} from 'app/selectors/enquiry-form';

function mapStateToProps(state) {
    return {
        getFieldTouched: (fieldName) => selectFieldTouched(state, fieldName),
        getFieldValue: (fieldName) => selectFieldValue(state, fieldName),
        submitted: selectSubmitted(state),
        isBookFunctionsActive: selectIsBookFunctionsActive(state),
        isBusy: selectIsBusy(state),
        submittedSuccessfully: selectSubmittedSuccessfully(state),
        venueOptions: selectVenueOptions(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setFieldValue: (fieldName, value) => dispatch(setEnquiryFormFieldValueAction(fieldName, value)),
        touchedField: (fieldName) => dispatch(touchedEnquiryFormFieldAction(fieldName)),
        submitForm: (formData) => dispatch(submitEnquiryFormAction(formData)),
        fetchVenueOptions: (location) => dispatch(fetchVenueOptionsAction(location))
    };
}

const VisibleEnquiryForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnquiryForm);

export default VisibleEnquiryForm;
