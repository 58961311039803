import Notification from './notification';
import PropTypes from 'prop-types';
import React from 'react';

const INTERVAL_PERIOD = 100;

class NotificationBanner extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggle = this.handleToggle.bind(this);

        this.state = {
            items: []
        };
    }


    // Lifecycle
    componentDidMount() {
        this.interval = setInterval(() => {
            const headroom = document.querySelector('.headroom');
            const navigationBarInner = document.querySelector('.navigation-bar-inner');

            if (window.matchMedia('(max-width: 800px)').matches) {
                navigationBarInner.style.top = `${headroom.offsetHeight}px`;
                navigationBarInner.style.height = `calc(100vh - ${headroom.offsetHeight}px)`;
            } else {
                navigationBarInner.style.top = 'auto';
                navigationBarInner.style.height = 'auto';
            }
        }, INTERVAL_PERIOD);
    }

    UNSAFE_componentWillUnmount() {
        clearInterval(this.interval);
    }


    // Handler
    handleToggle(id) {
        this.props.updateNotification(id);
    }


    // Render
    render() {
        const { notifications } = this.props;

        return (
            <div className="notification-banner">
                {notifications && notifications.map((item, index) => {
                    const {
                        description,
                        isPublished,
                        id,
                        title,
                        type,
                        url
                    } = item;

                    return (
                        <Notification
                            description={description}
                            id={id}
                            title={title}
                            type={type}
                            url={url}
                            handleToggle={this.handleToggle}
                            isVisible={isPublished}
                            key={index}
                        />
                    );
                })}
            </div>
        );
    }
}

NotificationBanner.propTypes = {
    notifications: PropTypes.array.isRequired,
    toggleNotifications: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired
};

export default NotificationBanner;
