/* eslint-disable require-jsdoc */
/* eslint-disable no-magic-numbers */
import moment from 'moment-timezone';

export function createCurrentNzDateObject() {
    // NZST is GMT+1200
    return new Date(new Date().getTime() + 12 * 60 * 60 * 1000);
}

export function dateObjectToTimeAsANumber(d) {
    const currentHoursNz = d.getUTCHours(); // 0 -- 23
    const currentMinutesNz = d.getUTCMinutes(); // 0 -- 59

    return currentHoursNz * 100 + currentMinutesNz;
}

function timeAsANumberToString(timeAsANumber, postfix) {
    const timeAsAString = timeAsANumber.toString();
    if (timeAsAString.length === 1) {
        return `0:0${timeAsAString}${postfix}`;
    }
    if (timeAsAString.length === 2) {
        return `0:${timeAsAString}${postfix}`;
    }
    if (timeAsAString.length === 3) {
        return `${timeAsAString.substr(0, 1)}:${timeAsAString.substr(1)}${postfix}`;
    }
    if (timeAsAString.length === 4) {
        return `${timeAsAString.substr(0, 2)}:${timeAsAString.substr(2)}${postfix}`;
    }

    return '';
}

// Possible timeAsA24HourNumber values: 420 (for '4:20am'), 1640 (for '4:40pm'), etc
export function processTime(timeAsA24HourNumber) {
    if (timeAsA24HourNumber < 100) {
        return timeAsANumberToString(timeAsA24HourNumber + 1200, 'am');
    }
    if (timeAsA24HourNumber < 1200) {
        return timeAsANumberToString(timeAsA24HourNumber, 'am');
    }
    if (timeAsA24HourNumber < 1300) {
        return timeAsANumberToString(timeAsA24HourNumber, 'pm');
    }

    return timeAsANumberToString(timeAsA24HourNumber - 1200, 'pm');
}

// Turns string 'friday' into 'Friday'
export function processWeekday(weekdayLowercase) {
    return weekdayLowercase.charAt(0).toUpperCase() + weekdayLowercase.slice(1);
}

export function momentNZ(date) {
    return moment(date).tz('Pacific/Auckland');
}

export function formatDate(startDate, endDate) {
    // momentTimezone.tz.setDefault("America/New_York");
    const formattedStartDate = momentNZ(startDate);
    const formattedEndDate = momentNZ(endDate);

    if (formattedStartDate.isSame(formattedEndDate, 'day')) {
        return formattedStartDate.format('DD MMMM YYYY');
    }

    if (formattedStartDate.isSame(formattedEndDate, 'month')) {
        return `${formattedStartDate.format('DD')} - ${formattedEndDate.format('DD MMMM YYYY')}`;
    }

    if (formattedStartDate.isSame(formattedEndDate, 'year')) {
        return `${formattedStartDate.format('DD MMMM')} - ${formattedEndDate.format('DD MMMM YYYY')}`;
    }

    return `${formattedStartDate.format('DD MMMM YYYY')} - ${formattedEndDate.format('DD MMMM YYYY')}`;
}
