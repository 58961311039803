export const optionGroupsConfig = [
    {
        heading: 'What would you like to hear about?',
        description: 'Select your subscription preferences below to receive information and exclusive offers about events you’re interested in.',
        options:[
            {
                value: '3967107',
                label: 'Event Announcements'
            },
            {
                value: '3967112',
                label: 'New On Sale'
            },
            {
                value: '3967179',
                label: 'Special Offers'
            }
        ]
    },
    {
        heading: 'Your interests',
        options: [
            {
                value: '3731082',
                label: 'Sport'
            },
            {
                value: '3731086',
                label: 'Family Fun'
            },
            {
                value: '3731088',
                label: 'Date Night'
            },
            {
                value: '3731089',
                label: 'Corporate Hospitality'
            },
            {
                value: '3731207',
                label: 'Rock'
            },
            {
                value: '3731216',
                label: 'Hip-Hop / RnB'
            },
            {
                value: '3731217',
                label: 'Dance/Electro'
            },
            {
                value: '3731218',
                label: 'Festivals'
            }
        ]
    },
];
