import classnames from 'classnames';
import PropTypes from 'prop-types';
import ticketImage from 'static/images/ticket.jpg';
import React, { Component } from 'react';

class SeatFinder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bayNumber: ''
        };
    }

    handleBayNumberChange(event) {
        this.setState({
            bayNumber: event.target.value,
            showError: false
        });
    }

    findSeat(event) {
        event.preventDefault();

        const { venue } = this.props;

        const bay = venue.seatCoordinates.find((bay) => this.state.bayNumber.toUpperCase() === bay.bayNumber);

        if (bay) {
            this.props.setBayInformation(bay);
        } else {
            this.props.setBayInformation(null);
            this.setState({ showError: true });
        }
    }

    render() {
        const { minSeatNumber, maxSeatNumber } = this.props.venue;
        const { selectedSeat } = this.props;

        const seatFinderClass = classnames('seat-finder-section', {
            'is-hidden': !this.props.isActive
        });

        const inputClass = classnames('way-finding-form-input', {
            'is-invalid': this.state.showError
        });

        return (
            <div className={seatFinderClass}>
                <span className="seat-finder-section-description">Enter you bay number to see your seat and which gate is best for you</span>
                <form className="way-finding-form find-seat" onSubmit={this.findSeat.bind(this)}>
                    <div className="way-finding-form-field">
                        <label className="way-finding-form-label" htmlFor="to">Bay number</label>
                        <input
                            className={inputClass}
                            type="text"
                            name="bayNumber"
                            id="bayNumber"
                            placeholder="15"
                            value={this.state.bayNumber}
                            onChange={this.handleBayNumberChange.bind(this)}
                        />
                        <button type="submit" className="button primary || way-finding-form-find-button">Find</button>
                    </div>
                </form>
                <div className="seat-finder-section-ticket">
                    {this.state.showError && <span className="seat-finder-section-ticket-error">This Bay does not exist - please enter a bay number between {minSeatNumber} and {maxSeatNumber}</span>}
                    <span className="seat-finder-section-ticket-description">How to find your bay number from your ticket</span>
                    <img className="seat-finder-section-ticket-image" src={ticketImage} alt="Ticket" />
                    {selectedSeat && <span className="seat-finder-section-ticket-directions">To get to your seat you need to enter from <strong>{selectedSeat.gate.title}</strong> and follow the signage to your section.</span>}
                </div>
            </div>
        );
    }
}

export default SeatFinder;

SeatFinder.propTypes = {
    isActive: PropTypes.bool.isRequired,
    setBayInformation: PropTypes.func.isRequired,
    venue: PropTypes.object.isRequired,
    selectedSeat: PropTypes.object
};
