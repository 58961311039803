import classnames from 'classnames';
import Headroom from 'react-headroom';
import Icon from 'app/components/partials/icon';
import Logo from 'app/components/partials/logo';
import Navigation from 'app/components/partials/navigation';
import NotificationBanner from 'app/components/partials/notification-banner.container';
import PropTypes from 'prop-types';
import SearchDialog from './search-dialog';
import updateBody from 'app/utilities/update-body';
import { useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const Header = ({ fetchNotifications, notifications, isNotificationsActive, toggleNotifications }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isNavigationActive, setIsNavigationActive] = useState(false);
    const [navigationItemOpen, setNavigationItemOpen] = useState(null);
    const searchDialogOpen = searchParams.get('search') === 'true';
    const location = useLocation();
    const status = useSelector((state) => state.status);

    const bellButtonIsVisible = (notifications.length > 0);

    useEffect(() => {
        fetchNotifications();
    }, []);

    // Handler
    const handleToggleNavigation = () => {
        // On close reset child navigation items
        if (isNavigationActive) {
            setNavigationItemOpen(null);
        }

        setIsNavigationActive(!isNavigationActive);
        // disable scroll when mobile nav is open
        updateBody(!isNavigationActive);

        if (isNotificationsActive && !isNavigationActive) {
            toggleNotifications();
        }
    };

    const handleToggleNotification = () => {
        window.scrollTo(0, 0);
        toggleNotifications();
    };

    const handleUpdateNavigationItem = (index = null) => {
        setNavigationItemOpen(index);
    };

    const handleCloseNavigation = () => {
        setIsNavigationActive(false);
        setNavigationItemOpen(null);
        // enable scroll when mobile nav is closed but only if search dialog is not open
        updateBody(searchDialogOpen);
    };

    // disable scroll if search dialog is open
    useEffect(() => {
        updateBody(searchDialogOpen);
    }, [searchParams]);

    // location change fold menu
    useEffect(() => {
        if (!status.isRouting) {
            handleCloseNavigation();
        }
    }, [location.pathname]);

    const handleToggleSearchDialog = () => {
        if (searchDialogOpen) {
            searchParams.delete('search');
            searchParams.delete('keyword');
            searchParams.delete('modalPage');
        } else {
            searchParams.set('search', true);
        }
        setSearchParams(searchParams);
    };

    // Render
    return (
        <>
            <Headroom
                downTolerance={20} // Scroll tolerance in px when scrolling down before component is pinned
                disableInlineStyles={true} // Relies on page.scss styling
            >
                {isNotificationsActive && <NotificationBanner notifications={notifications} />}
                <header className="header">
                    <div className="header-inner || constrain-width">
                        <Link className="header-logo" to="/">
                            <Logo
                                name="auckland-stadiums-logo"
                                title="Auckland Stadiums"
                            />
                        </Link>
                        <div className="header-side-actions">
                            <button className="search-icon" onClick={handleToggleSearchDialog}>
                                <Icon name="search" width="25" height="25" />
                            </button>
                            {bellButtonIsVisible && (
                                <button
                                    type="button"
                                    aria-label="Toggle Notifications"
                                    className={classnames('icon-button || header-notification-toggle', {
                                        'is-active': isNotificationsActive
                                    })}
                                    onClick={handleToggleNotification}>
                                    <Icon name="bell" title="Notifications" />
                                </button>
                            )}
                        </div>
                        <Navigation
                            isActive={isNavigationActive}
                            itemOpen={navigationItemOpen}
                            toggleActive={handleToggleNavigation}
                            updateOpenItem={handleUpdateNavigationItem}
                        />
                    </div>
                </header>
            </Headroom>
            {searchDialogOpen && <SearchDialog toggleSearchDialog={handleToggleSearchDialog} />}
        </>
    );
};

Header.propTypes = {
    fetchNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    isNotificationsActive: PropTypes.bool.isRequired,
    toggleNotifications: PropTypes.func.isRequired
};

export default Header;
