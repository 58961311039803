import { clientEnv } from 'config/env';
import fieldTypes from 'config/field-types';
import { generateInviteFormData } from 'app/ducks/invite-form';
import Loader from './loader';
import PropTypes from 'prop-types';
import React from 'react';
import { formIsValid, validate } from 'app/utilities/form-validation';

class EventReminderInvite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            captchaToken: null
        };

        this.setCaptchaToken = this.setCaptchaToken.bind(this);
    }

    // Life cycle
    componentDidMount() {
        // Need to explicitly load recaptcha v2 so that it works alongside v3
        grecaptcha.ready(() => { // eslint-disable-line no-undef
            grecaptcha.render('invite-recaptcha', { // eslint-disable-line no-undef
                sitekey: clientEnv.RECAPTCHA_KEY,
                callback: (response) => {
                    this.setCaptchaToken(response);
                },
            });
        });
    }

    UNSAFE_componentWillReceiveProps({ isBusy }) {
        if (isBusy) {
            grecaptcha.reset(); // eslint-disable-line no-undef
        }
    }

    // Helpers
    setCaptchaToken(captchaToken) {
        this.setState({ captchaToken });
    }

    // Handlers
    handleSubmit(event) {
        event.preventDefault();

        const { formFields, submitForm, submitted, pathname, eventName } = this.props;

        if (!submitted) {
            submitForm(generateInviteFormData(formFields, pathname, eventName), this.state.captchaToken);
        }
    }


    // Render
    renderFieldGroup(fieldGroup, setFieldGroup) {
        return (
            <div>
                {Object.keys(fieldGroup).map((fieldGroupItemName, fieldGroupItemIndex) => (
                    <div key={fieldGroupItemIndex}>
                        {this.renderField(
                            fieldGroup[fieldGroupItemName],
                            (nextFieldGroupItem) => {
                                setFieldGroup({
                                    ...fieldGroup,
                                    [fieldGroupItemName]: nextFieldGroupItem
                                });
                            }
                        )}
                    </div>
                ))}
            </div>
        );
    }

    renderField(field, setField) {
        const { label, placeholder, type, validatorType, fieldType, required, value, touched } = field;
        const validationData = validate(validatorType, value, touched);

        switch (fieldType) {
            case fieldTypes.FIELD_GROUP_REPEATER:
                return (
                    <div>
                        {value.map((fieldGroup, fieldGroupIndex) => (
                            <div key={fieldGroupIndex} className="invite-form-invitee">
                                {this.renderFieldGroup(
                                    fieldGroup,
                                    (nextFieldGroup) => {
                                        const nextValue = [...value];
                                        nextValue[fieldGroupIndex] = nextFieldGroup;
                                        setField({
                                            ...field,
                                            value: nextValue,
                                            touched: true
                                        });
                                    }
                                )}
                            </div>
                        ))}
                    </div>
                );
            case fieldTypes.INPUT:
            default:
                return (
                    <label className={`form-fieldset ${required ? 'is-required' : ''}`}>
                        <div className="form-label">{label}</div>
                        {!validationData.valid && (
                            <span className="form-validation">{validationData.message}</span>
                        )}
                        <input
                            className={`form-field || input ${validationData.valid ? '' : 'is-invalid'}`}
                            type={type}
                            placeholder={placeholder}
                            required={required}
                            value={value}
                            onChange={(event) => {
                                setField({
                                    ...field,
                                    value: event.target.value,
                                    touched: true
                                });
                            }}
                            onBlur={() => {
                                setField({
                                    ...field,
                                    touched: true
                                });
                            }}
                        />
                    </label>
                );
        }
    }

    renderSubmitButton() {
        const { formFields } = this.props;

        const invalid = !formIsValid(formFields);

        return (
            <button
                key="button"
                className="button primary large"
                type="submit"
                disabled={invalid || !this.state.captchaToken}>
                Invite
            </button>
        );
    }

    renderSubmitSection() {
        const { isBusy, submitted, submittedSuccessfully } = this.props;

        if (isBusy) {
            return <button type="button" className="button primary large"><Loader type="small" /></button>;
        }

        if (submitted) {
            if (submittedSuccessfully) {
                return [
                    <p key="p">Invited successfully.</p>,
                    <button key="button" type="button" className="button primary large">Sent</button>
                ];
            }

            return [
                <p key="p">Sorry, we couldn&apos;t invite. Please try again.</p>,
                this.renderSubmitButton()
            ];
        }

        return this.renderSubmitButton();
    }

    render() {
        const { formFields, setFormFields, addInvitee } = this.props;

        const { name, invitees } = formFields;

        return (

            <section id="invite" className="text-content-block || content-section">
                <div className="constrain-width">
                    <form
                        className="invite-form || form"
                        noValidate={true}
                        action="#"
                        onSubmit={this.handleSubmit.bind(this)}
                    >
                        <h3>Going with friends or family?</h3>
                        <p>Add their emails so they receive all of the updated event information too!</p>
                        {this.renderField(
                            name,
                            (nextName) => {
                                setFormFields({
                                    ...formFields,
                                    name: nextName
                                });
                            }
                        )}
                        {this.renderField(
                            invitees,
                            (nextInvitees) => {
                                setFormFields({
                                    ...formFields,
                                    invitees: nextInvitees
                                });
                            }
                        )}
                        <button
                            className="invite-form-add-invitee"
                            type="button"
                            onClick={() => {
                                addInvitee();
                            }}
                        >+ Add another person</button>
                        <div id="invite-recaptcha"></div>
                        <div className="invite-form-actions">
                            {this.renderSubmitSection()}
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}


EventReminderInvite.propTypes = {
    addInvitee: PropTypes.func.isRequired,
    formFields: PropTypes.object.isRequired,
    setFormFields: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired
};

export default EventReminderInvite;
