import ContentPage from './components/pages/content-page.container';
import ErrorPage from './components/pages/error/error-page';
import Footer from './components/partials/footer';
import Header from './components/partials/header.container';
import NewsletterPreferencesPage from './components/pages/newsletter-preferences-page';
import NewsletterThankYouPage from './components/pages/newsletter-thank-you-page';
import NotFoundPage from './components/pages/error/not-found-page';
import React from 'react';
import ScrollToTop from './components/partials/scroll-to-top';
import StyleGuidePage from './components/pages/style-guide-page';
import throttleUpdateWhileRouting from './utilities/throttle-update-while-routing';
import { Route, Routes } from 'react-router';

const Router = () => (
    <div className="base">
        <ScrollToTop />
        <Header />
        <Routes>
            {/* Custom */}
            <Route path="/style-guide" element={<StyleGuidePage />} />
            <Route path="/newsletter/preferences" element={<NewsletterPreferencesPage />} />
            <Route path="/newsletter/thank-you" element={<NewsletterThankYouPage />} />
            <Route path="/error-500" element={<ErrorPage />} />
            {/* API Based Routes */}
            <Route path="/" element={<ContentPage />} />
            <Route path="/event/:slug" element={<ContentPage />} />
            <Route path="/news/:slug" element={<ContentPage />} />
            <Route path="/our-venues/:slug" element={<ContentPage />} />
            <Route path="/performance/:slug" element={<ContentPage />} /> 
            <Route path="/:slug" element={<ContentPage />} />
            <Route path="/:parent/:slug" element={<ContentPage />} />
            {/* Catch */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
    </div>
);

export default throttleUpdateWhileRouting(Router);
