import classNames from 'classnames';
import logoSprite from 'static/images/logos/sprite.svg';
import PropTypes from 'prop-types';
import React from 'react';

function Logo({ name, svgAttributes, title, type }) { // eslint-disable-line require-jsdoc
    return (
        <svg
            className={classNames('logo', {
                'light' : type === 'light',
                'dark' : type !== 'light'
            })}
            {...svgAttributes}>
            <title>{title}</title>
            <use xlinkHref={`${logoSprite}#${name}`}></use>
        </svg>
    );
}

Logo.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    svgAttributes: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        viewBox: PropTypes.string
    })
};

Logo.defaultProps = {
    type: 'dark',
    svgAttributes: {
        height: 45,
        width: 145,
        viewBox: '0 0 305 96'
    }
};

export default Logo;
