import { EntityLink } from 'rfa-react-core';
import { formatDate } from 'app/utilities/date-time';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'app/utilities/truncate';

const TITLE_CHARACTER_LIMIT = 60;
const DESCRIPTION_CHARACTER_LIMIT = 80;

function renderTile(item, index) { // eslint-disable-line require-jsdoc
    const {
        name,
        thumbnail,
        short_description: description,
        venues
    } = item.data;

    const { type } = item;

    let formattedDate;

    if (type === 'event') {
        const { start_date: startDate, end_date: endDate } = item.data;

        formattedDate = formatDate(startDate, endDate);
    }

    return (
        <article className={`new-tile ${type === 'event' && 'new-tile-event'}`} key={index}>
            <EntityLink
                item={item}
                promoterBuName="Auckland Stadiums"
                className="new-tile-inner"
            >
                <div className="new-tile-image" style={{ backgroundImage: `url(${thumbnail})` }} />
                <div className="new-tile-description">
                    {type === 'event' && formatDate && <p className="event-date">{formattedDate}</p>}
                    <h5>{truncate(name, TITLE_CHARACTER_LIMIT)}</h5>
                    {Boolean(description) && <p>{truncate(description, DESCRIPTION_CHARACTER_LIMIT)}</p>}
                    {type === 'event' && venues && (
                        <p className="location">
                            <Icon name="location" />{venues}
                        </p>
                    )}
                </div>
            </EntityLink>
        </article>
    );
}

function ContentSet(props) { // eslint-disable-line require-jsdoc
    const { items, title } = props;

    return (
        <section className="tile-block">
            {title &&
                <header className="tile-block-title || constrain-width">
                    <h2 className="light">{title}</h2>
                </header>
            }
            <div className="constrain-width">
                <div className="tile-block-inner">
                    {items.map(renderTile)}
                </div>
            </div>
        </section>
    );
}

ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string
};

export default ContentSet;
